import { Link, Redirect } from 'expo-router';
import Head from 'expo-router/head';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { useCart } from '@fhs/cart';
import { useMenuClickEvents } from '@fhs/menu/src/analytics';
import { ScreenMenuListWithQuery } from '@fhs/menu/src/screens/screen-menu-list';
import { Button, MobileBottomBarServiceModeLocation, MqSwitch, YStack, tokens } from '@fhs/ui';
import { useLocale } from '@fhs-legacy/frontend/src/state/intl';
import { REGIONS } from '@fhs-legacy/frontend/src/state/intl/types';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { isSSG } from '@fhs-legacy/frontend/src/utils/environment';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { useServiceModeLocation } from '../../../../utils/use-get-service-mode-location-type';
export { ErrorBoundary } from '@fhs/routing/src/components/page-dev-error-boundary';

export default function MenuIndexPage() {
  const { cart, isPending } = useCart();

  const { store } = useStoreContext();
  const { region } = useLocale();
  const { isDelivery } = useServiceModeContext();
  const { logEnabledCartCTAClick } = useMenuClickEvents();

  // TODO(simplified-menu): Remove when simplified menu is out of beta
  const { numCartPreviewEntries } = useOrderContext();

  const serviceModeLocationProps = useServiceModeLocation();

  // TODO(simplified-menu): Remove when simplified menu is out of beta
  const enableSimplifiedMenuBeta = useFlag<boolean>(LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA);
  const enableSimplifiedMenuBetaDetailView = useFlag<boolean>(
    LaunchDarklyFlag.ENABLE_SIMPLIFIED_MENU_BETA_DETAIL_VIEW
  );
  const enableSimplifiedCartService = useFlag<boolean>(
    LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE
  );

  // The flag will be `undefined` initially, so we need to return null to avoid rendering the `menu` page,
  // because that will cause a redirect to the store locator if the store is not set.
  if (enableSimplifiedMenuBeta === undefined && !isSSG) {
    return <ActivityIndicator />;
  }
  if (!enableSimplifiedMenuBeta && !isSSG) {
    return <Redirect href="/menu" />;
  }
  // END of TODO

  // Defaulting to `0` returns the global menu, w/o pricing and availability
  const selectedRestaurantId = store.number || '0';
  const serviceMode = isDelivery ? 'delivery' : 'pickup';
  const regionAsUnionType = region === REGIONS.US ? 'us' : 'ca';

  // TODO, maybe entries shouldn't be `null`?
  // TODO(simplified-menu): Remove ternary check when simplified menu is out of beta. We need both flags to be consitent in this case (both enabled or both disabled).
  const cartEntriesLength =
    enableSimplifiedMenuBetaDetailView && enableSimplifiedCartService
      ? cart?.entries?.length || 0
      : numCartPreviewEntries;
  const cartHasEntries = cartEntriesLength > 0;
  const cartEntriesSuffix = cartEntriesLength === 1 ? 'Item' : 'Items';
  const buttonText = cartHasEntries
    ? `Review Order (${cartEntriesLength} ${cartEntriesSuffix})`
    : 'Your Cart is Empty';
  const buttonDisabled = !cartHasEntries;

  return (
    <>
      <Head>
        <title>Menu - Firehouse Subs</title>
      </Head>
      <SafeAreaView edges={['right', 'bottom', 'left']} style={styles.safeArea}>
        <View style={styles.flex1}>
          <ScreenMenuListWithQuery
            selectedRestaurantId={selectedRestaurantId}
            region={regionAsUnionType}
            serviceMode={serviceMode}
            // TODO(simplified-menu): remove this after 100% rollout
            // Temporary prop to link to the legacy menu detail view
            linkToLegacyMenuDetailView={!enableSimplifiedMenuBetaDetailView}
          />
        </View>
        <MqSwitch
          $ltDesktop={() => (
            <YStack style={styles.footer}>
              <Link href={isDelivery ? routes.address : routes.storeLocator} asChild>
                <MobileBottomBarServiceModeLocation {...serviceModeLocationProps} />
              </Link>
              <View style={styles.footerButtonContainer}>
                <Link href="/cart" asChild>
                  <Button
                    loading={isPending}
                    style={styles.button}
                    disabled={buttonDisabled}
                    onPress={() => {
                      logEnabledCartCTAClick(cartEntriesLength);
                    }}
                  >
                    {!isPending && (
                      <Button.Text style={styles.buttonText}>{buttonText}</Button.Text>
                    )}
                  </Button>
                </Link>
              </View>
            </YStack>
          )}
        />
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  button: {
    flex: 1,
    height: 48,
  },
  buttonText: {
    fontSize: 18,
    lineHeight: 25,
  },
  flex1: {
    flex: 1,
  },
  safeArea: {
    backgroundColor: tokens.colors.$white,
    flex: 1,
  },
  footer: {
    maxWidth: '100%',
    overflow: 'hidden',
  },
  footerButtonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 12,
  },
});
