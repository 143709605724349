import {
  ClickEventComponentNames,
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from '@fhs-legacy/frontend/src/state/crm-events';

// Amplitude tracking click events
export const useMenuClickEvents = () => {
  const { logRBIEvent } = useCRMEventsContext();

  const logMenuClickEvent = ({
    text,
    type,
    component = ClickEventComponentNames.BUTTON,
    serviceMode,
  }: {
    text: string;
    type?: string;
    component?: string;
    serviceMode?: string;
  }) => {
    logRBIEvent({
      name: CustomEventNames.CLICK_EVENT,
      type: EventTypes.Other,
      attributes: {
        component,
        text: text,
        currentScreen: '/menu',
        element: type,
        ...(serviceMode ? { serviceMode } : {}),
      },
    });
  };

  const logMenuTabClick = (tabName: string) => {
    logMenuClickEvent({ text: tabName, type: 'tab' });
  };

  const logMenuItemClick = (itemName: string) => {
    logMenuClickEvent({ text: itemName, type: 'item' });
  };

  const logEnabledCartCTAClick = (cartEntries: number) => {
    logMenuClickEvent({
      text: `${cartEntries} Items`,
      component: ClickEventComponentNames.CART_CTA,
    });
  };

  const logServiceModeBarClick = ({
    serviceMode,
    storeText,
  }: {
    serviceMode: string;
    storeText: string;
  }) => {
    logMenuClickEvent({
      text: storeText,
      serviceMode,
      component: ClickEventComponentNames.SERVICE_MODE,
    });
  };

  return {
    logMenuClickEvent,
    logMenuTabClick,
    logMenuItemClick,
    logEnabledCartCTAClick,
    logServiceModeBarClick,
  };
};
