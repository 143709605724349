import { View, ViewProps } from 'react-native';

import { Text } from '@fhs/ui';

export const GeographicalFee = ({
  style,
  formattedFee,
  feeExplanationText,
}: {
  style?: ViewProps['style'];
  formattedFee: string;
  feeExplanationText: string;
}) => {
  return (
    <View style={style}>
      <Text.Heading type="five">Geographical Fee: {formattedFee}</Text.Heading>
      {feeExplanationText && <Text.Paragraph size="sm">{feeExplanationText}</Text.Paragraph>}
    </View>
  );
};
