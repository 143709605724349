import { IStoreAddress } from '@rbi-ctg/store';

export const getGeoFeeMessage = (address: IStoreAddress | null): string => {
  if (!address) {
    return '';
  }

  const { stateProvinceShort, stateProvince, country } = address;

  // TODO(simply-store-locator): Move this to the BFF
  if (country === 'US' && (stateProvince === 'Colorado' || stateProvinceShort === 'CO')) {
    return 'We are required by law to charge a Retail Delivery Fee of $.29 for deliveries in the State of Colorado.';
  }

  return '';
};
