import { router } from 'expo-router';
import { useCallback } from 'react';

import { sleep } from '@fhs/utils';
import { routes } from '@fhs-legacy/frontend/src/utils/routing';

import { Offer, useAddIncentiveToCart } from './use-add-incentive-to-cart';
import { useLoyaltyLegacyStates } from './use-loyalty-legacy-states';

export function useApplyOffer() {
  const onSuccess = useCallback(() => {
    router.navigate(routes.menu);
  }, []);

  const { addIncentiveToCart } = useAddIncentiveToCart({
    onSuccess,
  });

  const { isAuthenticated } = useLoyaltyLegacyStates();
  const applyOffer = useCallback(
    async (offer: Offer) => {
      if (!offer.isAvailable) {
        router.navigate(routes.offersV2Details + `/${offer.id}`);
        return;
      }
      if (!isAuthenticated) {
        router.navigate(routes.signIn);
        return;
      }
      if (offer.requireGuideFlow) {
        router.navigate(routes.offersV2Guide + `/${offer.id}`);
        return;
      }
      await sleep(300);
      addIncentiveToCart({ offer, showMessage: true });
    },
    [addIncentiveToCart, isAuthenticated]
  );
  return { applyOffer };
}
