import React, { useCallback } from 'react';

import { create } from '@fhs/zustand';
import ReplaceIncentiveModal from '@fhs-legacy/frontend/src/components/replace-incentive-modal';
import { IncentiveType } from '@fhs-legacy/frontend/src/components/replace-incentive-modal/types';
import { IBaseProps } from '@rbi-ctg/frontend';

interface IReplaceIncentiveCallbacks {
  onConfirm?: () => void;
  onDismiss?: () => void;
}

interface IReplaceIncentiveUiStore {
  isOpen: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  show: (callbacks: IReplaceIncentiveCallbacks) => void;
  close: () => void;
}

export const useReplaceIncentiveUiStore = create<IReplaceIncentiveUiStore>(set => ({
  isOpen: false,
  onConfirm: () => {},
  onDismiss: () => {},
  show: (callbacks: IReplaceIncentiveCallbacks) =>
    set({ isOpen: true, onConfirm: callbacks.onConfirm, onDismiss: callbacks.onDismiss }),
  close: () => set({ isOpen: false, onConfirm: () => {}, onDismiss: () => {} }),
}));

export const ReplaceIncentiveUiProvider = ({ children }: IBaseProps) => {
  const { isOpen, close, onConfirm, onDismiss } = useReplaceIncentiveUiStore();

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    close();
  }, [close, onConfirm]);

  const handleDismiss = useCallback(() => {
    if (onDismiss) {
      onDismiss();
    }
    close();
  }, [close, onDismiss]);

  return (
    <>
      {children}
      {isOpen && (
        <ReplaceIncentiveModal
          incentiveType={IncentiveType.INCENTIVE}
          onConfirm={handleConfirm}
          onDismiss={handleDismiss}
          isOpen={isOpen}
        />
      )}
    </>
  );
};
