import { useEffect, useRef } from 'react';
import { Animated, Modal, StyleSheet, View } from 'react-native';

import { IconCartFill, IconCartOutline, Text, tokens } from '@fhs/ui';

import { useCart } from '../api';

export function CartCommitLoading() {
  const { cart } = useCart();

  if (cart?.state !== 'INSERT_REQUESTED') {
    return null;
  }

  return <LoadingScreen />;
}

function LoadingScreen() {
  const cartFill = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.loop(
      Animated.timing(cartFill.current, {
        duration: 6000,
        toValue: 800,
        useNativeDriver: false,
      })
    ).start();
  }, []);

  const translateY = cartFill.current.interpolate({
    inputRange: [0, 100, 500, 800],
    outputRange: [0, -5, -60, -80],
  });

  const translateYOpacity = cartFill.current.interpolate({
    inputRange: [0, 750, 800],
    outputRange: [1, 1, 0],
  });

  return (
    <Modal animationType="slide">
      <View style={styles.container}>
        <View>
          <IconCartOutline size={80} style={{ zIndex: 1 }} />
          <View style={{ position: 'absolute' }}>
            <Animated.View style={[styles.cartBlocker, { transform: [{ translateY }] }]} />
            <Animated.View style={{ opacity: translateYOpacity }}>
              <IconCartFill size={80} />
            </Animated.View>
          </View>
        </View>
        <Text.Heading type="one">Hang tight!</Text.Heading>
        <View style={styles.bottom}>
          <Text.Ui size="md">We're sending your order to the store now</Text.Ui>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  cartBlocker: {
    zIndex: 1,
    opacity: 1,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: tokens.colors.$houseYellow,
  },
  bottom: {
    position: 'absolute',
    bottom: 28,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: tokens.colors.$houseYellow,
  },
});
