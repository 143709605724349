import { signInStore } from '@aws-amplify/auth/dist/esm/client/utils/store/signInStore.mjs';
import * as Auth from 'aws-amplify/auth';

export const signOut = async (shouldSignOutGlobally: boolean = false): Promise<void> => {
  return Auth.signOut({ global: shouldSignOutGlobally });
};

export const cognitoSignIn = async (
  cognitoId,
  sessionId,
  challengeCode
): Promise<Auth.AuthSession> => {
  //Run a temporary sign out first
  try {
    await signOut(true);
  } catch (e) {}
  //Create a Dummy sign in attempt.
  const initialSignIn = await Auth.signIn({
    username: cognitoId,
    options: { authFlowType: 'CUSTOM_WITHOUT_SRP' },
  });

  //This is done to manually set the session ID to the value provided by the backend, this is a hack to emulate the previous login system.
  __HACK_OVERRIDE_SESSION(sessionId);

  if (initialSignIn.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
    const confirm = await Auth.confirmSignIn({
      challengeResponse: challengeCode,
    });

    if (confirm.isSignedIn) {
      return Auth.fetchAuthSession();
    } else {
      throw Error('Sign in error');
    }
  }
};

/**
 * This is a hack to take the backend session ID and utilize it in a frontend. This is not a good practice,
 * but is needed to emulate the current sign in behavior.
 *
 * In the future, we should instead start the sign in attempt on the frontend, and pass those credentials to the backend.
 */
function __HACK_OVERRIDE_SESSION(sessionId: string) {
  signInStore.dispatch({
    type: 'SET_SIGN_IN_SESSION',
    value: sessionId,
  });
}
