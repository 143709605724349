import { useCart } from '@fhs/cart';
import { ServiceModeLocationProps, ServiceModeLocationType, useMqSelect } from '@fhs/ui';
import { LaunchDarklyFlag, useFlag } from '@fhs-legacy/frontend/src/state/launchdarkly';
import { useOrderContext } from '@fhs-legacy/frontend/src/state/order';
import { ServiceMode, useServiceModeContext } from '@fhs-legacy/frontend/src/state/service-mode';
import { DeliveryFees } from '@fhs-legacy/frontend/src/state/service-mode/types';
import { useStoreContext } from '@fhs-legacy/frontend/src/state/store';
import { useUIContext } from '@fhs-legacy/frontend/src/state/ui';

function getTypeFromServiceMode(opts: {
  serviceMode?: ServiceMode;
  enableDeliveryFeeOnServiceModeBar?: boolean;
}): ServiceModeLocationType {
  if (!opts.serviceMode) {
    return 'location';
  }

  if (
    opts.serviceMode === ServiceMode.CATERING_DELIVERY ||
    opts.serviceMode === ServiceMode.CATERING_PICKUP
  ) {
    return 'catering';
  }

  if (opts.serviceMode === ServiceMode.DELIVERY) {
    return opts.enableDeliveryFeeOnServiceModeBar ? 'deliveryWithFees' : 'delivery';
  }

  return 'pickup';
}

type RetValue = Pick<
  ServiceModeLocationProps,
  'loading' | 'type' | 'storeText' | 'serviceModeSubtitle'
>;

export function useServiceModeLocation(): RetValue {
  const cartServiceEnabled = useFlag(LaunchDarklyFlag.ENABLE_SIMPLY_BETTER_CART_SERVICE);

  const _cartService = _useCartServiceModeLocation();
  const _context = _useContextServiceModeLocation();

  return cartServiceEnabled ? _cartService : _context;
}

function _useCartServiceModeLocation(): RetValue {
  const { cart, isPending } = useCart();
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();
  const enableDeliveryFeeOnServiceModeBar = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEE_ON_SERVICE_MODE_BAR)
  );

  const storeTextPickup = useMqSelect(
    {
      $gteDesktop: cart?.store?.name,
    },
    [cart?.store?.name, cart?.store?.addressLine1].filter(Boolean).join(' • ')
  );

  if (isPending || !cart?.store) {
    return {
      loading: isPending,
      type: 'location',
      serviceModeSubtitle: '',
      storeText: '',
    };
  }

  // TODO: Hook up to cart service
  let deliveryFees = null as DeliveryFees | null;

  const storeText = cart.serviceMode === 'DELIVERY' ? 'TODO: Delivery Address' : storeTextPickup;

  return {
    loading: isPending,
    type: getTypeFromServiceMode({
      serviceMode: cart.serviceMode as ServiceMode,
      enableDeliveryFeeOnServiceModeBar,
    }),
    storeText,
    serviceModeSubtitle:
      enableDeliveryFeeOnServiceModeBar && deliveryFees?.deliveryTotalFee
        ? `${currencyFormatter(deliveryFees.deliveryTotalFee)} Delivery Fee (Per Order)}`
        : '',
  };
}

function _useContextServiceModeLocation() {
  const { serviceMode, deliveryFees } = useServiceModeContext();
  const enableDeliveryFeeOnServiceModeBar = Boolean(
    useFlag(LaunchDarklyFlag.SHOW_DELIVERY_FEE_ON_SERVICE_MODE_BAR)
  );
  const isDelivery = serviceMode === ServiceMode.DELIVERY;

  const { store } = useStoreContext();
  const { deliveryAddress } = useOrderContext();

  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();

  const type = getTypeFromServiceMode({ serviceMode, enableDeliveryFeeOnServiceModeBar });

  const nonDelivery = {
    loading: false,
    type,
    serviceModeSubtitle: '',
    storeText: useMqSelect(
      {
        $gteDesktop: store.name,
      },
      [store.name, store.physicalAddress?.address1].filter(Boolean).join(' • ')
    ),
  };

  if (!isDelivery) {
    return nonDelivery;
  }

  return {
    loading: false,
    type,
    storeText: deliveryAddress?.addressLine1,
    serviceModeSubtitle:
      enableDeliveryFeeOnServiceModeBar && deliveryFees?.deliveryTotalFee
        ? `${currencyFormatter(deliveryFees.deliveryTotalFee)} Delivery Fee (Per Order)}`
        : '',
  };
}
