import { router, useLocalSearchParams } from 'expo-router';
import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { ActionSheet, tokens } from '@fhs/ui';

import { useCartSubscription } from '../api';
import { CartActionRemoveItem } from '../components/cart-action-remove-item';
import { CartActionRemoveOffer } from '../components/cart-action-remove-offer';

import { CartScreen as CartReviewScreen } from './_cart';

const onClose = () => router.setParams({ action: undefined, id: undefined });

export function CartScreen() {
  const { cart, isPending } = useCartSubscription({ throwOnError: true });

  const { action, id } = useLocalSearchParams<{
    action: 'remove-offer' | 'remove-item';
    id: string;
  }>();
  const showRemoveOfferSelector = action === 'remove-offer';
  const showRemoveItemSelector = action === 'remove-item';

  if (isPending) {
    return (
      <View style={styles.page}>
        <ActivityIndicator />
      </View>
    );
  }

  if (!cart) {
    throw new Error('UndetectableCartError');
  }

  return (
    <>
      <View style={styles.page}>
        <View style={styles.inner}>
          <CartReviewScreen cart={cart} />
        </View>
      </View>
      {showRemoveOfferSelector && (
        <ActionSheet isVisible onClose={onClose}>
          <CartActionRemoveOffer id={id} onClose={onClose} />
        </ActionSheet>
      )}
      {showRemoveItemSelector && (
        <ActionSheet isVisible onClose={onClose}>
          <CartActionRemoveItem id={id} onClose={onClose} />
        </ActionSheet>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    flex: 1,
  },
  inner: {
    flex: 1,
    width: '100%',
    maxWidth: 908,
    marginHorizontal: 'auto',
  },
});
