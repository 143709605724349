const BASE_MENU_URL = '/v2/menu';

export const getUrlForItemDetailPageFromSlug = (slug: string) => {
  return `${BASE_MENU_URL}/${slug}`;
};

export const formatCentsToDollars = (cents: number) => {
  // Always include decimal places
  return cents > 0 ? `$${(cents / 100).toFixed(2)}` : null;
};

export const formatCentsToDollarsWithPlusSign = (cents: number) => {
  return cents > 0 ? `+ ${formatCentsToDollars(cents)}` : null;
};

export const formatCaloriesOffsetText = (caloriesOffset?: number): string => {
  // We still want to display this information when calories are 0.
  return caloriesOffset !== null || caloriesOffset !== undefined ? `${caloriesOffset} Cal` : '';
};
