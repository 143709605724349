import { Link, router } from 'expo-router';
import { useEffect, useMemo } from 'react';
import { SafeAreaView, SectionList, StyleSheet, View } from 'react-native';

import { Schema } from '@fhs/backend';
import { useMutation } from '@fhs/client';
import { Button, tokens } from '@fhs/ui';
import { Toast } from '@fhs-legacy/native-base';

import { priceCart } from '../api';
import { CartDonationSwitch } from '../components/cart-donation-switcher';
import { CartEmptyState } from '../components/cart-empty-state';
import { CartItem } from '../components/cart-item';
import { CartMissingAnything } from '../components/cart-missing-anything';
import { CartOfferItem } from '../components/cart-offer-item';
import { CartOffersAndPromosLink } from '../components/cart-offers-and-promos-link';

export type ItemTypeEntry = {
  type: 'Item';
  entry: Schema['CartItemEntry']['type'] & { quantity: number };
};
export type OfferTypeEntry = {
  type: 'Offer';
  entries: (Schema['CartItemEntry']['type'] & { quantity: number })[];
  incentive: Schema['CartIncentive']['type'];
};

type Entries = ItemTypeEntry | OfferTypeEntry;
type Data =
  | {
      title: 'Donation';
      data: { key: string }[];
    }
  | { title: 'MissingAnything'; data: any[] }
  | { title: 'OffersAndPromos'; data: any[] }
  | { title: 'Subtotals'; data: any[] }
  | { title: 'CartEntries'; data: Entries[] };

export function CartScreen({ cart }: { cart: Schema['Cart']['type'] }) {
  const {
    isPending: isPricingCart,
    error,
    mutate: priceOrder,
  } = useMutation({
    ...priceCart,
    onSuccess() {
      router.replace('/cart/checkout');
    },
  });

  useEffect(() => {
    if (error) {
      Toast.show({ title: error.message });
    }
  }, [error]);

  const entries = useMemo(() => flattenEntriesToDeriveQuantity(cart.entries ?? []), [cart.entries]);

  const data = useMemo<Data[]>(() => {
    // return empty array to trigger ListEmptyComponent
    if (entries.length === 0) {
      return [];
    }

    return [
      { title: 'Donation', data: [{ key: 'donation' }] },
      {
        title: 'CartEntries',
        data: mapOffersAndEntries(cart.appliedIncentives, entries),
      },
      {
        title: 'MissingAnything',
        data: [void 0],
      },
      {
        title: 'OffersAndPromos',
        data: [void 0],
      },
    ];
  }, [entries, cart.appliedIncentives]);

  return (
    <View style={styles.page}>
      <SectionList
        sections={data}
        style={styles.flex1}
        ListEmptyComponent={CartEmptyState}
        renderItem={({ item, section }) => {
          switch (section.title) {
            case 'Donation':
              return <CartDonationSwitch donationAmount={cart.donationAmount ?? 0} />;
            case 'MissingAnything':
              return <CartMissingAnything />;
            case 'OffersAndPromos':
              return <CartOffersAndPromosLink />;
            case 'CartEntries':
              switch (item.type) {
                case 'Offer':
                  return (
                    <View style={styles.itemContainer}>
                      <CartOfferItem incentive={item.incentive} entries={item.entries} />
                    </View>
                  );
                case 'Item':
                default:
                  return (
                    <View style={styles.itemContainer}>
                      <CartItem item={item.entry} canUsePoints={!!item.entry.pointCost} />
                    </View>
                  );
              }
            default:
              return null;
          }
        }}
      />
      <SafeAreaView>
        <View style={styles.container}>
          {data.length === 0 ? (
            <Link href="/menu" asChild>
              <Button size="xl" type="outline">
                <Button.Text>Add Items</Button.Text>
              </Button>
            </Link>
          ) : (
            <Button size="xl" loading={isPricingCart} onPress={() => priceOrder()}>
              <Button.Text>Continue</Button.Text>
            </Button>
          )}
        </View>
      </SafeAreaView>
    </View>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    flex: 1,
  },
  flex1: {
    flex: 1,
  },
  container: {
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$blackOpacity10,
    width: '100%',
    padding: 16,
  },
  itemContainer: {
    borderBottomWidth: 1,
    borderBottomColor: tokens.colors.$blackOpacity04,
  },
});

// cart entries contains all items in the basket
// but in our UI we need to bisect the array into two seperate
// arrays. One of normal cart entries, and one where the entries
// are paired with the offer that "owns" them.
// - offers should always be displayed first
function mapOffersAndEntries(
  appliedIncentives: Schema['CartIncentive']['type'][] = [],
  oEntries: (Schema['CartItemEntry']['type'] & { quantity: number })[] = []
): Entries[] {
  const entries = [...oEntries].filter(
    ({ lineId }) => !appliedIncentives.some(s => (s.appliedCartEntries ?? []).includes(lineId))
  );

  return [
    ...appliedIncentives.map(
      (incentive): OfferTypeEntry => ({
        type: 'Offer',
        incentive,
        entries: oEntries.filter(({ lineId }) =>
          (incentive.appliedCartEntries ?? []).includes(lineId)
        ),
      })
    ),
    ...entries.map(
      (entry): ItemTypeEntry => ({
        type: 'Item',
        entry,
      })
    ),
  ];
}

function flattenEntriesToDeriveQuantity(entries: Schema['CartItemEntry']['type'][]) {
  // This holds a reference of a line id to the index it exists
  // in the array of entries.
  const lineMap = {};

  return entries.reduce((acc, item) => {
    if (item.lineId in lineMap === false) {
      lineMap[item.lineId] = acc.length;
    }
    const index = lineMap[item.lineId];
    acc[index] ||= { ...item, quantity: 0 };
    acc[index].quantity++;
    return acc;
  }, [] as (Schema['CartItemEntry']['type'] & { quantity: number })[]);
}
