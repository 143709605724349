import { useCallback, useState } from 'react';
import { View } from 'react-native';

import {
  Button,
  EmailInput,
  FormControlTextInput,
  NameInput,
  PhoneNumberMaskInput,
  Text,
  XStack,
  createMqStyles,
} from '@fhs/ui';

import { useAuthV2 } from '../backend';
import { formatPhoneNumber } from '../utils';

export function SignUp() {
  const styles = useMqStyles();

  const { getJwtIdentifier, signUp } = useAuthV2();
  const { validatedIdentifiers } = getJwtIdentifier();

  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>(validatedIdentifiers.emailAddress ?? '');
  const [phone, setPhone] = useState<string>(formatPhoneNumber(validatedIdentifiers.phoneNumber));

  const [dobDay, setDobDay] = useState<string>('00');
  const [dobMonth, setDobMonth] = useState<string>('00');
  const [dobYear, setDobYear] = useState<string>('1974');

  const attemptSignUp = useCallback(async () => {
    await signUp(name, email, phone, `${dobYear}-${dobDay}-${dobMonth}`, false);
  }, [signUp, name, email, phone, dobDay, dobMonth, dobYear]);

  return (
    <View style={{ padding: 8 }}>
      <Text.Heading type={'three'}>Create an Account </Text.Heading>
      <NameInput value={name} onChange={setName} />
      <EmailInput
        value={email}
        onChange={setEmail}
        readOnly={!!validatedIdentifiers.emailAddress}
      />
      <PhoneNumberMaskInput
        value={phone}
        onChange={setPhone}
        readOnly={!!validatedIdentifiers.phoneNumber}
      />
      <View style={styles.dobContainer}>
        <Text weight="semibold"> Date Of Birth (Optional)</Text>
        <XStack style={styles.dateContainer}>
          <FormControlTextInput
            placeholder={dobDay}
            maxLength={2}
            keyboardType="numeric"
            onChangeText={setDobDay}
            style={styles.dobText}
          />
          <FormControlTextInput
            placeholder={dobMonth}
            maxLength={2}
            keyboardType="numeric"
            onChangeText={setDobMonth}
            style={styles.dobText}
          />
          <FormControlTextInput
            maxLength={4}
            placeholder={dobYear}
            keyboardType="numeric"
            onChangeText={setDobYear}
            style={styles.dobText}
          />
        </XStack>
      </View>
      <View style={styles.continueButton}>
        <Button onPress={attemptSignUp}>
          <Button.Text>Create Account</Button.Text>
        </Button>
      </View>
    </View>
  );
}

const useMqStyles = createMqStyles({
  dateContainer: {
    $base: {
      marginVertical: 6,
      gap: 12,
      width: '100%',
    },
  },
  dobContainer: {
    $base: {
      marginVertical: 12,
      width: '100%',
    },
  },
  dobText: {
    $base: {
      textAlign: 'center',
    },
  },
  continueButton: {
    $base: {
      width: '100%',
      height: 48,
    },
  },
});
