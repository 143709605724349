import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { router, useGlobalSearchParams } from 'expo-router';
import type { LDContext } from 'launchdarkly-js-client-sdk';
import { cloneDeepWith, isPlainObject, pickBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { DeepPartial } from '@rbi-ctg/frontend';
import {
  IsoCountryCode,
  Platform,
  useCreateOtpMutation,
  useSignInJwtMutation,
  useSignUpMutation,
  useValidateAuthJwtMutation,
  useValidateOtpMutation,
} from 'generated/graphql-gateway';
import { ModalCb } from 'hooks/use-error-modal';
import {
  signOut as cognitoSignOut,
  validateLogin as cognitoValidateLogin,
} from 'remote/auth/cognito';
import { useCRMEventsContext } from 'state/crm-events';
import { SignInPhases } from 'state/crm-events/types';
import { useFlag, useLDContext } from 'state/launchdarkly';
import AuthStorage from 'utils/cognito/storage';
import { platform, welcomeEmailDomain } from 'utils/environment';
import { parseGraphQLErrorCodes } from 'utils/errors';
import { GraphQLErrorCodes } from 'utils/errors/types';
import { LaunchDarklyFlag } from 'utils/launchdarkly';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { OTPAuthDeliveryMethod } from 'utils/otp';
import { routes } from 'utils/routing';

import { isOTPEmailEnabled, isOTPSMSEnabled } from '../../../utils/otp/is-flag-enabled';
import { SIGN_IN_FAIL } from '../constants';
import { JwtValidationError, OtpValidationError, UserNotFoundError } from '../errors';

import { useThirdPartyAuthentication } from './use-third-party-authentication';

const NON_SESSION_SPECIFIC_STORAGE_KEYS = [StorageKeys.LAST_TIME_COOKIES_ACCEPTED];

export interface IUseAccountAuthentication {
  refreshCurrentUser(): Promise<void>;
  openErrorDialog: ModalCb;
  setCurrentUser(session: null | CognitoUserSession): void;
}

interface INavigateOnSuccessParams {
  email: string;
}

interface ISignInNavigation {
  navigateOnSuccess?: boolean;
  navigateOnSuccessParams?: INavigateOnSuccessParams;
}

interface ISignInUserParams {
  email?: string;
  phoneNumber?: string;
}

export type ISignIn = ISignInNavigation & ISignInUserParams;

interface IFavoriteStore {
  storeId?: string;
  storeNumber: string;
}

interface ISignUp {
  email: string;
  name: string;
  dob?: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails: boolean;
  zipcode?: string;
  favoriteStores?: IFavoriteStore[];
}

export interface ISignUpResult {
  jwt: string | null | undefined;
}

interface IValidateLogin {
  jwt: string;
  username: string;
}

interface IGetSesionIdAndChallengeCodeOtp {
  email?: string;
  phoneNumber?: string;
  otpCode: string;
  sessionId: string;
}

type IStoreOtpCredentials = { sessionId: string } & ISignInUserParams;

export const getStoredOtpCredentials = () => LocalStorage.getItem(StorageKeys.OTP);
export const storeOtpCredentials = (data: IStoreOtpCredentials) =>
  LocalStorage.setItem(StorageKeys.OTP, data);

export const useAccountAuthentication = ({
  refreshCurrentUser,
  openErrorDialog,
  setCurrentUser,
}: IUseAccountAuthentication) => {
  const { formatMessage } = useIntl();
  const { attemptGetUpdatedLdFlag } = useLDContext();

  const { signInEvent, signUpEvent } = useCRMEventsContext();
  const { logUserOutOfThirdPartyServices } = useThirdPartyAuthentication();
  const useBackendGeneratedSessionId = useFlag(LaunchDarklyFlag.AUTH_FLOW_USE_BACKEND_SESSION_ID);
  const enableUserNotFoundMaskAuthFlow = useFlag(
    LaunchDarklyFlag.ENABLE_USER_NOT_FOUND_MASK_ON_AUTH_FLOW
  );
  const preloaded = LocalStorage.getItem(StorageKeys.AUTH_REDIRECT) || {};
  const [originLocation, setOriginLoc] = useState<null | string>(preloaded.callbackUrl || null);
  const routeParams = useGlobalSearchParams();

  const [signInJwtMutation, { loading: signInMutationLoading }] = useSignInJwtMutation();
  const [validateAuthJwtMutation, { loading: validateAuthMutationLoading }] =
    useValidateAuthJwtMutation();
  const [createOtpMutation, { loading: createOtpMutationLoading }] = useCreateOtpMutation();
  const [validateOtpMutation, { loading: validateOtpMutationLoading }] = useValidateOtpMutation();
  const [signUpMutation] = useSignUpMutation();

  const getSessionIdAndChallengeCodeOtp = useCallback(
    async ({ email, phoneNumber, otpCode, sessionId }: IGetSesionIdAndChallengeCodeOtp) => {
      const { data } = await validateOtpMutation({
        variables: {
          input: {
            code: otpCode,
            email,
            phoneNumber,
            sessionId,
          },
        },
      });

      const {
        sessionId: validatedSessionId,
        challengeCode,
        email: validatedEmail,
      } = data?.exchangeOTPCodeForCognitoCredentials ?? {};

      if (!validatedSessionId || !challengeCode) {
        throw new OtpValidationError('GraphQL validation failed');
      }

      return { sessionId: validatedSessionId, code: challengeCode, email: validatedEmail };
    },
    [validateOtpMutation]
  );

  useEffect(() => {
    if (routeParams.clearOrigin) {
      setOriginLoc(null);
    }
  }, [routeParams.clearOrigin]);

  const getSessionIdAndChallengeCode = useCallback(
    async (jwt: string) => {
      try {
        const { data } = await validateAuthJwtMutation({
          variables: {
            input: { jwt },
          },
        });
        const { sessionId, challengeCode } = data?.validateAuthJwt ?? {};
        if (!sessionId || !challengeCode) {
          throw new JwtValidationError();
        }

        return { sessionId, code: challengeCode };
      } catch (error) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (error?.originalError?.[0]?.message?.toLowerCase() === 'email not registered') {
          throw new UserNotFoundError();
        }
        throw error;
      }
    },
    [validateAuthJwtMutation]
  );

  const signOut = useCallback(async () => {
    try {
      await cognitoSignOut();
      setCurrentUser(null);
      logUserOutOfThirdPartyServices();

      // Once we have namespaced auth storage for all platforms we can remove the excluded
      // keys from here but for now we need to make sure we don't wipe all LocalStorage
      LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });
    } catch (error) {
      refreshCurrentUser();
      throw error;
    }
  }, [setCurrentUser, logUserOutOfThirdPartyServices, refreshCurrentUser]);

  const signInWithOtp = useCallback(
    async (param: { email: string } | { phoneNumber: string }) => {
      const results = await createOtpMutation({
        variables: {
          input: {
            ...param,
            // RBIPlatform and Platform are the same enum but from different sources.
            platform: platform() as unknown as Platform,
            // @deprecated field. Sending empty string allows backend to determine sessionId
            sessionId: '',
          },
        },
      });
      const sessionId = results.data?.createOTP?.sessionId;
      if (!sessionId) {
        throw new Error('Session ID not returned from createOTP mutation');
      }
      storeOtpCredentials({ ...param, sessionId });
      return (navigateOnSuccessParams?: INavigateOnSuccessParams) => {
        router.replace({
          pathname: routes.confirmOtp,
          params: {
            ...routeParams,
            ...navigateOnSuccessParams,
            showOtpForm: 'true',
            activeRouteIsSignIn: 'true',
          },
        });
      };
    },
    [createOtpMutation, routeParams, useBackendGeneratedSessionId]
  );

  const signInWithJwt = useCallback(
    async (email: string) => {
      LocalStorage.setItem(StorageKeys.LOGIN, email);
      await signInJwtMutation({
        variables: {
          input: {
            email,
            stage: welcomeEmailDomain(),
            platform: platform(),
          },
        },
      });
      return (navigateOnSuccessParams?: INavigateOnSuccessParams) => {
        const params = navigateOnSuccessParams ? { email: navigateOnSuccessParams.email } : {};
        router.navigate({ pathname: routes.authChallengeJwt, params });
      };
    },
    [signInJwtMutation]
  );

  const getOtpFlagValueForAttributes = useCallback(
    async (ldAttributes: DeepPartial<LDContext>) => {
      // omit nested empty/null values
      const attributes = cloneDeepWith(ldAttributes, value =>
        isPlainObject(value) ? pickBy(value, v => !!v) : !!value
      );

      const flagValue = await attemptGetUpdatedLdFlag(
        LaunchDarklyFlag.ENABLE_ONE_TIME_PASSWORD,
        attributes,
        OTPAuthDeliveryMethod.Email
      );
      return flagValue;
    },
    [attemptGetUpdatedLdFlag]
  );

  const signInUsingEnabledMethod = useCallback(
    async ({
      email,
      phoneNumber,
      otpMethod,
    }: Pick<ISignIn, 'email' | 'phoneNumber'> & { otpMethod: OTPAuthDeliveryMethod }) => {
      const isEmailEnabled = isOTPEmailEnabled(otpMethod);
      const isSMSEnabled = isOTPSMSEnabled(otpMethod);

      if (isSMSEnabled && phoneNumber) {
        return signInWithOtp({ phoneNumber });
      }

      if (!email) {
        throw new Error('No email provided and SMS login disabled');
      }

      if (isEmailEnabled) {
        return signInWithOtp({ email });
      }

      return signInWithJwt(email);
    },
    [signInWithJwt, signInWithOtp]
  );

  const signIn = useCallback(
    async ({ email, phoneNumber, navigateOnSuccess = true, navigateOnSuccessParams }: ISignIn) => {
      const otpMethod: OTPAuthDeliveryMethod = await getOtpFlagValueForAttributes({
        email,
        custom: { phoneNumber },
      });
      try {
        const navigateOnSuccessFn = await signInUsingEnabledMethod({
          email,
          phoneNumber,
          otpMethod,
        });
        signInEvent({
          phase: SignInPhases.START,
          success: true,
        });
        if (navigateOnSuccess) {
          navigateOnSuccessFn(navigateOnSuccessParams);
        }
      } catch (error) {
        const createOtpError = enableUserNotFoundMaskAuthFlow
          ? GraphQLErrorCodes.CREATE_OTP_FAILED
          : GraphQLErrorCodes.AUTH_EMAIL_NOT_REGISTERED;
        // @ts-expect-error TS(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
        const notRegisteredError = parseGraphQLErrorCodes(error)
          .map(err => err.errorCode)
          .includes(createOtpError);
        if (!notRegisteredError) {
          signInEvent({
            phase: SignInPhases.START,
            success: false,
          });
        }
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        error.code = SIGN_IN_FAIL;
        throw error;
      }
    },
    [getOtpFlagValueForAttributes, signInEvent, signInUsingEnabledMethod]
  );

  const signUp = useCallback(
    async ({
      email,
      name,
      dob,
      phoneNumber,
      country,
      wantsPromotionalEmails,
      zipcode,
      favoriteStores,
    }: ISignUp): Promise<ISignUpResult> => {
      let jwt;
      try {
        const { data } = await signUpMutation({
          variables: {
            input: {
              country: country as IsoCountryCode,
              dob,
              name,
              phoneNumber,
              platform: platform(),
              stage: welcomeEmailDomain(),
              userName: email,
              wantsPromotionalEmails,
              zipcode,
              favoriteStores,
            },
          },
        });
        jwt = data?.signUp;
      } catch (error) {
        signUpEvent({ success: false });

        throw error;
      }

      signUpEvent({ success: true });

      return { jwt };
    },
    [signUpEvent, signUpMutation]
  );

  const validateLoginOtp = useCallback(
    async ({ otpCode }: any) => {
      try {
        let { email } = getStoredOtpCredentials() ?? {};
        const { phoneNumber, sessionId: storedSessionId } = getStoredOtpCredentials() ?? {};
        if (!storedSessionId) {
          throw new OtpValidationError('Missing sessionId');
        }

        const validateOtpResponse = await getSessionIdAndChallengeCodeOtp({
          email,
          phoneNumber,
          otpCode,
          sessionId: storedSessionId,
        });

        if (!email) {
          email = validateOtpResponse.email;
        }

        const { sessionId, code } = validateOtpResponse;

        const session = await cognitoValidateLogin({ username: email, code, sessionId });
        // This must be set __BEFORE__ the `setCurrentUser` state update.
        // we use this local storage value to know if we should skip fetching the user data from sanity.
        // by setting this ahead of time, we know that the user is valid and we can fetch user data.
        AuthStorage.setItem(
          StorageKeys.USER_AUTH_TOKEN,
          session?.getAccessToken().payload.username
        );

        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
        });
        setCurrentUser(session);
      } catch (error) {
        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
        });

        throw error;
      }
    },
    [getSessionIdAndChallengeCodeOtp, signInEvent, setCurrentUser]
  );

  const validateLogin = useCallback(
    async ({ jwt, username }: IValidateLogin) => {
      try {
        const { sessionId, code } = await getSessionIdAndChallengeCode(jwt);
        const session = await cognitoValidateLogin({ username, code, sessionId });
        signInEvent({ phase: SignInPhases.COMPLETE, success: true });

        // This must be set __BEFORE__ the `setCurrentUser` state update.
        // we use this local storage value to know if we should skip fetching the user data from sanity.
        // by setting this ahead of time, we know that the user is valid and we can fetch user data.
        AuthStorage.setItem(
          StorageKeys.USER_AUTH_TOKEN,
          session?.getAccessToken().payload.username
        );
        setCurrentUser(session);
        // Add a marker when a user is successfull login to track unexpected sign outs
        LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
      } catch (error) {
        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
        });
        openErrorDialog({
          // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
          error,
          message: formatMessage({ id: 'authError' }),
          modalAppearanceEventMessage: 'Error: JWT Validation Failure',
        });
        throw error;
      }
    },
    [formatMessage, getSessionIdAndChallengeCode, signInEvent, openErrorDialog, setCurrentUser]
  );

  return {
    authLoading:
      signInMutationLoading ||
      validateAuthMutationLoading ||
      createOtpMutationLoading ||
      validateOtpMutationLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signOut,
    signUp,
    validateLogin,
    validateLoginOtp,
  };
};
