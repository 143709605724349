import React from 'react';

import { ICombo, IItem, IPicker } from '@rbi-ctg/menu';
import FormatMenuPrice from 'components/menu-utils/format-menu-price';
import { MenuObjectTypes } from 'enums/menu';

import { MainMenuObject } from './types';

interface IFormattedCaloriesAndPriceRangeForMenuObject {
  (
    menuObject: IPicker | MainMenuObject,
    getCaloriesRange: (
      menuObject: ICombo | IItem | IPicker | MainMenuObject,
      displayNutritionWithModifiersFromSanity: boolean
    ) => string,
    getPrices: (menuObject: ICombo | IItem | IPicker | MainMenuObject) => React.ReactNode,
    displayNutritionWithModifiersFromSanity?: boolean
  ): { calories: string; price: React.ReactNode };
}

const formattedCaloriesAndPriceRangeForMenuObject: IFormattedCaloriesAndPriceRangeForMenuObject = (
  menuObject,
  getCaloriesRange,
  getPrices,
  displayNutritionWithModifiersFromSanity = false
) => {
  const price = getPrices(menuObject);
  return {
    calories: getCaloriesRange(menuObject, displayNutritionWithModifiersFromSanity),
    price,
  };
};

interface IGetCaloriesAndPrice {
  (
    menuObject: IPicker | MainMenuObject,
    getCaloriesRange: (
      menuObject: ICombo | IItem,
      displayNutritionWithModifiersFromSanity: boolean
    ) => string,
    displayNutritionWithModifiersFromSanity?: boolean
  ): { calories: string; price: React.ReactNode };
}

export const getCaloriesAndPrice: IGetCaloriesAndPrice = (
  menuObject,
  getCaloriesRange,
  displayNutritionWithModifiersFromSanity
) => {
  const getPrices = (comboOrItem: ICombo | IItem) => {
    return <FormatMenuPrice menuItem={comboOrItem} />;
  };

  return formattedCaloriesAndPriceRangeForMenuObject(
    menuObject,
    // @ts-expect-error TS(2345) FIXME: Argument of type '(menuObject: IItem | ICombo, dis... Remove this comment to see the full error message
    getCaloriesRange,
    getPrices,
    displayNutritionWithModifiersFromSanity
  );
};

export const findPromotion = (option: MainMenuObject) => {
  const optionWithPromo =
    option._type === MenuObjectTypes.PICKER
      ? option.options?.find(opt => !!opt.option.promotion)?.option
      : option;

  return optionWithPromo?.promotion;
};
