import { useApolloClient } from '@apollo/client';
import { differenceBy, intersectionBy, isEqual } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { OfferDiscountTypes } from 'enums/menu';
import { useLoyaltyUserTransactionsQuery } from 'generated/graphql-gateway';
import {
  GetOrderDocument,
  RbiOrderStatus,
  useGetUserOrdersLazyQuery,
  usePriceOrderMutation,
  useUpdateOrderMutation,
} from 'generated/rbi-graphql';
import { getPosVendorFromStore } from 'hooks/menu/use-pos-vendor';
import useDebouncedEffect from 'hooks/use-debounce-effect';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useLocalStorageState } from 'hooks/use-local-storage-state';
import { usePathname } from 'hooks/use-pathname';
import { useSetResetCartTimeout } from 'hooks/use-set-reset-cart-timeout';
import { useToast } from 'hooks/use-toast';
import { MAX_TIPS_AMOUNT_IN_CENTS } from 'pages/cart/your-cart/totals/use-pickup-tips';
import { useAuthContext } from 'state/auth';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { useErrorContext } from 'state/errors';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { removeAppliedOffersInStorage } from 'state/global-state/models/loyalty/offers/offers.utils';
import { removeAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyContext } from 'state/loyalty';
import { useGetAvailableRewards } from 'state/loyalty/hooks/use-get-available-rewards';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { getCmsOffersMapByCmsId } from 'state/loyalty/utils';
import { useMainMenuContext } from 'state/main-menu';
import { useMenuContext } from 'state/menu';
import { useOffersContext } from 'state/offers';
import { useCartTip } from 'state/order/hooks/use-cart-tip';
import { usePaymentContext } from 'state/payment';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode } from 'state/service-mode/types';
import { useStoreContext } from 'state/store';
import { useGetPosData } from 'state/store/hooks/use-pos-data-query';
import { useUIContext } from 'state/ui';
import { getUnavailableCartEntries } from 'utils/availability';
import {
  CartEntryType,
  buildPriceDeliveryInput,
  buildStoreAddress,
  getParentIdFromUrl,
  remappedCartForBackEnd,
} from 'utils/cart';
import {
  computeCartTotal,
  computeTotalDiscountAmount,
  computeTotalWithoutOffers,
} from 'utils/cart/helper';
import { redeemReward } from 'utils/cart/redeem-reward';
import * as DatadogLogger from 'utils/datadog';
import { brand, isTest, platform } from 'utils/environment';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import logger, { addLoggingContext } from 'utils/logger';
import noop from 'utils/noop';
import { routes } from 'utils/routing';
import { isCatering as isCateringOrder } from 'utils/service-mode';
import { TOAST_ITEM_ADDED_TO_CART } from 'utils/test-ids';
import { PerformanceMarks, setMark } from 'utils/timing';

import { TipAmounts } from './constants';
import useAlertOrderCateringMinimum from './hooks/use-alert-order-catering-min';
import useAlertOrderLimit from './hooks/use-alert-order-limit';
import { useHandleReorder } from './hooks/use-handle-reorder';
import useRewardDiscount from './hooks/use-reward-discount';
import { useUnavailableCartEntries } from './hooks/use-unavailable-cart-entries';
import { orderPollFailure, orderPollSuccessful } from './order-state-utils';
import { preloadedOrder } from './preloaded-order';
import { DeliveryStatus, OrderStatus } from './types';
import { determineLimit, replaceEntryArrayItem, validateCartEntries } from './utils';

export { OrderStatus, ServiceMode, TipAmounts };
export const OrderContext = React.createContext();
export const useOrderContext = () => useContext(OrderContext);

export function OrderProvider(props) {
  const apolloClient = useApolloClient();
  const preloaded = useMemo(() => preloadedOrder(), []);
  const toast = useToast();

  const tipPercentThresholdCents = useFlag(LaunchDarklyFlag.TIP_PERCENT_THRESHOLD_CENTS) || 0;
  const enableMenuServiceData = useFlag(LaunchDarklyFlag.ENABLE_MENU_SERVICE_DATA);
  const CART_VERSION = useFlag(LaunchDarklyFlag.ORDER_CART_VERSION) || preloaded.cartVersion;
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const auth = useAuthContext();
  const billingCountry = auth?.user?.details?.isoCountryCode || ISOs.USA;
  const { currencyCode } = getCountryAndCurrencyCodes(billingCountry);
  const { locale: customerLocale } = useLocale();
  const { logRBIEvent, logAddOrRemoveFromCart, logPurchase } = useCRMEventsContext();
  const { pricingFunction, priceForItemOptionModifier, priceForItemInComboSlotSelection } =
    useMenuContext();
  const { getPaymentMethods } = usePaymentContext();
  const {
    tipAmount,
    setTipAmount,
    tipSelection,
    setTipSelection,
    updateTipAmount,
    shouldShowTipPercentage,
  } = useCartTip();

  const {
    prices,
    resetStore,
    selectStore: selectNewStore,
    store,
    resetLastTimeStoreUpdated,
    isStoreAvailable,
    updateUserStoreWithCallback,
  } = useStoreContext();
  const { storeMenuLoading } = useMainMenuContext();
  const { serviceMode, setServiceMode } = useServiceModeContext();
  const pathname = usePathname();
  const offers = useOffersContext();
  const { setCurrentOrderId } = useErrorContext();
  const { getAvailableRewardFromCartEntry } = useGetAvailableRewards();
  const { refetchLoyaltyUser, evaluateEligibleItems } = useLoyaltyContext();
  const { loyaltyUser } = useLoyaltyUser();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const loyaltyCmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const incentivesIds = useAppSelector(selectors.loyalty.selectIncentivesIds);

  const discountAppliedCmsOffers = useAppSelector(selectors.loyalty.selectDiscountAppliedCmsOffer);
  const offersEligibleItems = useAppSelector(selectors.loyalty.selectOffersEligibleItems);
  const loyaltySelectedOffer = useAppSelector(selectors.loyalty.selectSelectedOffer);

  const dispatch = useAppDispatch();

  const loyaltyUserId = loyaltyUser?.id;
  const appliedLoyaltyOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const selectedOfferSelections = useAppSelector(selectors.loyalty.selectSelectedOfferSelections);
  const appliedRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);

  const { refetch: refetchLoyaltyUserTransaction, data } = useLoyaltyUserTransactionsQuery({
    skip: !loyaltyUserId,
    variables: { loyaltyId: loyaltyUserId || '' },
  });

  useEffect(() => {
    if (data?.loyaltyUserV2?.transactions) {
      dispatch(actions.loyalty.setUserTransactions(data.loyaltyUserV2.transactions));
    }
  }, [data?.loyaltyUserV2?.transactions]);

  const [refetchGetUserOrders] = useGetUserOrdersLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: 1,
      orderStatuses: [RbiOrderStatus.INSERT_SUCCESSFUL, RbiOrderStatus.UPDATE_SUCCESSFUL],
    },
  });

  const [executeUpdateOrderMutation] = useUpdateOrderMutation();
  const [executePriceOrderMutation] = usePriceOrderMutation();

  const [pendingReorder, setPendingReorder] = useState(null);
  const [reordering, setReordering] = useState(false);
  const [reorderedOrderId, setReorderedOrderId] = useState(null);

  const [cartIdEditing, setCartIdEditing] = useState(preloaded.cartIdEditing || '');
  const [cartEntries, setCartEntries] = useState(preloaded.cartEntries || []);

  const [curbsidePickupOrderId, setCurbsidePickupOrderId] = useState(
    preloaded.curbsidePickupOrderId || ''
  );
  const [curbsidePickupOrderTimePlaced, setCurbsidePickupOrderTimePlaced] = useState(
    preloaded.curbsidePickupOrderTimePlaced || ''
  );
  const [serverOrder, setServerOrder] = useState({});
  const [quoteId, setQuoteId] = useState(preloaded.quoteId || '');
  const [cateringPickupDateTime, setOrderCateringPickupDateTime] = useState(
    preloaded.cateringPickupDateTime || ''
  );
  const [deliveryAddress, setDeliveryAddress] = useState(preloaded.deliveryAddress || {});
  const [deliveryInstructions, setDeliveryInstructions] = useState(
    preloaded.deliveryInstructions || ''
  );

  const getPosData = useGetPosData();

  const [fetchingPosData, setFetchingPosData] = useState(false);

  const [orderPhoneNumber, setOrderPhoneNumber] = useState(() => preloaded.orderPhoneNumber || '');
  React.useEffect(() => {
    if (orderPhoneNumber) {
      return;
    }
    if (auth?.user?.details?.phoneNumber) {
      setOrderPhoneNumber(auth?.user?.details?.phoneNumber);
    }
  }, [auth, orderPhoneNumber]);

  const [fireOrderIn, setFireOrderIn] = useState(0);
  const [cartPriceLimitExceeded, setCartPriceLimitExceeded] = useState(false);

  const [cartCateringPriceTooLow, setCartCateringPriceTooLow] = useState(false);

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const refPriceOrderTimeout = useRef(null);

  const { unavailableCartEntries, setUnavailableCartEntries } = useUnavailableCartEntries({
    serverOrder,
    cartEntries,
  });
  const [pendingRecentItem, setPendingRecentItem] = useState();
  const [pendingRecentItemNeedsReprice, setPendingRecentItemNeedsReprice] = useState(false);

  // th specific: reward information
  const { cartHasRewardEligibleItem } = useRewardDiscount({
    serverOrder,
    cartEntries,
  });

  const updateShouldSaveDeliveryAddress = useCallback(shouldSaveDeliveryAddress => {
    setDeliveryAddress(previousAddress => ({
      ...previousAddress,
      shouldSave: shouldSaveDeliveryAddress,
    }));
  }, []);

  const orderLimitMessage = (maxLimit, maxCateringLimit, isOrderCatering) => {
    const limit = determineLimit({ maxLimit, maxCateringLimit, isCatering: isOrderCatering });
    return formatMessage(
      { id: 'orderLimitMessage' },
      {
        maxLimit: formatCurrencyForLocale(limit),
      }
    );
  };

  const [isDonationZeroAmountSelected, setIsDonationZeroAmountSelected] = useState(false);

  // Pick Up Tips
  const [selectedPickupTip, setSelectedPickupTip, clearSelectedPickupTip] = useLocalStorageState({
    key: StorageKeys.PICK_UP_TIPS_SELECTED,
    defaultReturnValue: '0',
  });
  // Pick Up Tips in case user selected "Other" option
  const [otherPickupTip, setOtherPickupTip, clearOtherPickupTip] = useLocalStorageState({
    key: StorageKeys.OTHER_PICK_UP_TIPS_SELECTED,
    defaultReturnValue: undefined,
  });
  const pickUpTipsCents =
    selectedPickupTip === 'other' ? Number(otherPickupTip) : Number(selectedPickupTip);
  const pickUpTipsInputError =
    pickUpTipsCents > MAX_TIPS_AMOUNT_IN_CENTS
      ? `Maximum tip allowed is ${formatCurrencyForLocale(MAX_TIPS_AMOUNT_IN_CENTS)}`
      : undefined;

  const resetPickUpTips = useCallback(() => {
    clearSelectedPickupTip();
    clearOtherPickupTip();
  }, [clearSelectedPickupTip, clearOtherPickupTip]);

  const emptyCart = useCallback(() => {
    setCartEntries([]);
    setCartIdEditing('');
    offers.clearSelectedOffer({ doLog: false });
    offers.setRedemptionMethod(null);
    setTipAmount(0);
    setTipSelection({
      percentAmount: TipAmounts.PERCENT_DEFAULT,
      dollarAmount: TipAmounts.DOLLAR_DEFAULT,
      otherAmount: 0,
      isOtherSelected: false,
    });

    resetPickUpTips();

    removeAppliedOffersInStorage();
    removeAppliedRewardsInStorage();

    if (loyaltyEnabled) {
      dispatch(actions.loyalty.resetAppliedOffers());
      dispatch(
        actions.loyalty.resetLoyaltyRewardsState({
          points: loyaltyUser?.points ?? 0,
          shouldResetAvailableRewardsMap: false,
        })
      );
    }
    if (isDonationZeroAmountSelected) {
      setIsDonationZeroAmountSelected(false);
    }
  }, [
    offers,
    setTipAmount,
    setTipSelection,
    loyaltyEnabled,
    isDonationZeroAmountSelected,
    dispatch,
    loyaltyUser?.points,
    resetPickUpTips,
  ]);

  useSetResetCartTimeout({
    storageKey: StorageKeys.ORDER_LAST_UPDATE,
    cart: cartEntries,
    resetCartCallback: emptyCart,
  });

  useEffectOnUpdates(() => {
    if (!auth.isAuthenticated) {
      emptyCart();
    }
  }, [auth.user]);

  const verifyCartVersion = useCallback(
    (version, { onFailure = noop, onSuccess = noop }) => {
      if (version !== CART_VERSION) {
        onFailure();
        return;
      }
      onSuccess();
    },
    [CART_VERSION]
  );

  useEffect(() => {
    const updateStoredCartVersion = () =>
      LocalStorage.setItem(StorageKeys.ORDER, {
        ...preloaded,
        cartVersion: CART_VERSION,
      });

    const cartVersionArgs = {
      message: cartEntries.length > 0 ? formatMessage({ id: 'outdatedCartVersionMessage' }) : '',
      onFailure: () => {
        if (cartEntries.length > 0) {
          emptyCart();
        }
        updateStoredCartVersion();
      },
    };

    verifyCartVersion(preloaded.cartVersion || 0, cartVersionArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CART_VERSION]);

  useEffect(() => {
    setCurrentOrderId(serverOrder.rbiOrderId);
  }, [serverOrder, setCurrentOrderId]);

  useEffectOnUpdates(() => {
    // Creating a map out of all valid offers in the CMS
    const cmsOffersMap = getCmsOffersMapByCmsId(loyaltyCmsOffers);
    appliedOffers.forEach(({ cartId, cmsId }) => {
      // If the applied offer is not in the cms offers map, should remove it
      const shouldRemoveCartEntry = !cmsId || !cmsOffersMap[cmsId];
      if (shouldRemoveCartEntry) {
        // Removing offer from cart will also remove it from applied offers
        removeFromCart({ cartId });
      }
    });
  }, [loyaltyCmsOffers]);

  const selectServiceMode = useCallback(
    newMode => {
      logRBIEvent({
        name: CustomEventNames.SELECT_SERVICE_MODE,
        type: EventTypes.Other,
        attributes: null,
      });
      setFireOrderIn(0);
      setTipSelection({
        percentAmount: TipAmounts.PERCENT_DEFAULT,
        dollarAmount: TipAmounts.DOLLAR_DEFAULT,
        otherAmount: 0,
        isOtherSelected: false,
      });
      setServiceMode(newMode);
      resetLastTimeStoreUpdated();
      return Promise.resolve(true);
    },
    [logRBIEvent, setTipSelection, setServiceMode, resetLastTimeStoreUpdated]
  );

  const {
    onConfirmRedemption,
    selectedOffer,
    isSelectedOfferCartEntry,
    selectedOfferCartEntry,
    clearSelectedOffer,
  } = offers;

  const shouldOfferBeRemoved = useCallback(
    item => {
      const cartEntriesWithRemovedItem = cartEntries.filter(entry => entry._id !== item._id);
      const offerDetails = {
        selectedOffer: offers.selectedOffer,
        selectedOfferCartEntry: offers.selectedOfferCartEntry,
        selectedOfferPrice: offers.selectedOfferPrice,
      };

      let total = computeCartTotal(offerDetails, cartEntriesWithRemovedItem, {
        loyaltyEnabled,
        appliedLoyaltyRewards,
        appliedLoyaltyOfferDiscount: discountAppliedCmsOffers?.incentives?.[0],
        offersEligibleItems,
        loyaltySelectedOffer,
      });
      total = total < 0 ? 0 : total;

      const offerPrice = offers?.selectedOffer?.option?.discountValue || 0;
      // TODO: this line does not account for percentage type discounts
      return total < offerPrice * 100;
    },
    [
      appliedLoyaltyRewards,
      cartEntries,
      loyaltyEnabled,
      offers,
      discountAppliedCmsOffers,
      offersEligibleItems,
      selectedOffer,
    ]
  );

  const getOfferText = item =>
    shouldOfferBeRemoved(item) ? formatMessage({ id: 'removeItemFromCartOfferText' }) : '';

  const removeItemMessage = item => {
    return item
      ? formatMessage(
          { id: 'removeItemFromCart' },
          {
            item: item.name,
            offerText: getOfferText(item),
          }
        )
      : '';
  };

  const logCartEntryRemovedFromCart = useCallback(
    cartEntry => {
      logAddOrRemoveFromCart({ action: 'remove', cartEntry, previousCartEntries: cartEntries });
    },
    [cartEntries, logAddOrRemoveFromCart]
  );

  const removeOfferFromCart = useCallback(() => {
    return selectedOffer && clearSelectedOffer();
  }, [clearSelectedOffer, selectedOffer]);

  const removeRewardIfNeeded = useCallback(
    cartEntry => {
      const { cartId } = cartEntry;
      const cartEntryReward = getAvailableRewardFromCartEntry(cartEntry);

      const isRewardApplied = !!appliedLoyaltyRewards?.[cartId]?.timesApplied;
      if (loyaltyEnabled && isRewardApplied && cartEntryReward) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: cartEntryReward.rewardBenefitId,
            cartId,
          })
        );
      }
    },
    [appliedLoyaltyRewards, getAvailableRewardFromCartEntry, loyaltyEnabled, dispatch]
  );

  // TODO: Remove this function
  // Updates the cartIdEditing to the new entry only if it exists
  const editCart = useCallback(
    cartId => {
      if (
        isSelectedOfferCartEntry({ cartId }) ||
        cartEntries.find(({ cartId: entryCartId }) => entryCartId === cartId)
      ) {
        setCartIdEditing(cartId);
      }
    },
    [cartEntries, isSelectedOfferCartEntry]
  );

  // Returns the currently editing cartEntry or undefined if the entry no longer exists
  const getCurrentCartEntry = useCallback(() => {
    return isSelectedOfferCartEntry({ cartId: cartIdEditing })
      ? selectedOfferCartEntry
      : cartEntries.find(entry => entry.cartId === cartIdEditing);
  }, [cartEntries, cartIdEditing, isSelectedOfferCartEntry, selectedOfferCartEntry]);

  // Removes all provided cartEntries from the cart using their cartIds
  const removeAllFromCart = useCallback(
    (cartEntriesToRemove = []) => {
      const cartEntryIdsToRemove = new Set(cartEntriesToRemove.map(entry => entry.cartId));
      setCartEntries(prevCartEntries =>
        prevCartEntries.filter(entry => !cartEntryIdsToRemove.has(entry.cartId))
      );
      cartEntriesToRemove.forEach(cartEntry => {
        removeRewardIfNeeded(cartEntry);
        logCartEntryRemovedFromCart(cartEntry);
        dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntry));
      });
    },
    [dispatch, logCartEntryRemovedFromCart, removeRewardIfNeeded]
  );

  // Removes a single cartEntry using its cartId
  const removeFromCart = useCallback(
    ({ cartId }) => {
      // Entry to remove can be an offer cart entry
      const isOffer = selectedOfferCartEntry?.cartId === cartId;
      const cartEntryToRemove = isOffer
        ? selectedOfferCartEntry
        : cartEntries.find(entry => entry.cartId === cartId);

      if (!cartEntryToRemove) {
        return;
      }
      dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntryToRemove));
      setCartEntries(prevCartEntries =>
        prevCartEntries.filter(entry => cartEntryToRemove.cartId !== entry.cartId)
      );
      logCartEntryRemovedFromCart(cartEntryToRemove);

      if (isOffer || shouldOfferBeRemoved(cartEntryToRemove)) {
        removeOfferFromCart();
      }
      // removes applied rewards associated to cart entry on removal
      removeRewardIfNeeded(cartEntryToRemove);

      // Resets the availability of Surprise so it wont show in cart page
      dispatch(actions.loyalty.resetSurpriseAvailability());
    },
    [
      selectedOfferCartEntry,
      cartEntries,
      dispatch,
      logCartEntryRemovedFromCart,
      shouldOfferBeRemoved,
      removeRewardIfNeeded,
      removeOfferFromCart,
    ]
  );

  // Changed this to a debounced effect because it was creating infinite loops in some scenarios.
  // e.g. Navigating through rewards and offers pages, going back to cart, then removing offers was causing this.
  useDebouncedEffect(
    () => {
      if (incentivesIds.size > 0) {
        validateCartEntries(cartEntries, appliedOffers, incentivesIds, removeFromCart);
      }
    },
    250,
    [appliedOffers, cartEntries, incentivesIds, removeFromCart]
  );

  const shouldEmptyCart = useCallback(
    ({ cartId }) => {
      const appliedOffersMap = appliedOffers.reduce((acc, appliedOffer) => {
        if (appliedOffer.cartId) {
          acc[appliedOffer.cartId] = appliedOffer;
        }
        return acc;
      }, {});

      const entriesNotRemoved = cartEntries.filter(entry => entry.cartId !== cartId);
      const entryIsDonationOrSurpriseOrExtra = entry =>
        entry.isDonation || appliedOffersMap[entry?.cartId]?.isSurprise || entry.isExtra;

      return entriesNotRemoved.every(entryIsDonationOrSurpriseOrExtra);
    },
    [appliedOffers, cartEntries]
  );

  const onConfirmRemoveItemDialog = useCallback(
    entry => {
      return shouldEmptyCart(entry) ? emptyCart() : removeFromCart(entry);
    },
    [emptyCart, removeFromCart, shouldEmptyCart]
  );

  const [RemoveItemDialog, openRemoveItemDialog, itemToRm] = useDialogModal({
    onConfirm: onConfirmRemoveItemDialog,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Remove item from cart',
  });

  // for confirming item removal
  const confirmRemoveFromCart = useCallback(
    cartId => {
      const isOffer = selectedOfferCartEntry?.cartId === cartId;
      // Entry to remove can be an offer cart entry
      const entryToRemove = isOffer
        ? selectedOfferCartEntry
        : cartEntries.find(item => cartId === item.cartId);
      openRemoveItemDialog(entryToRemove);
    },
    [cartEntries, openRemoveItemDialog, selectedOfferCartEntry]
  );

  const calculateCartTotalWithoutOffers = useCallback(() => {
    return computeTotalWithoutOffers(cartEntries, { loyaltyEnabled, appliedLoyaltyRewards });
  }, [appliedLoyaltyRewards, cartEntries, loyaltyEnabled]);

  const calculateCartTotalWithDiscount = useCallback(
    paramCartEntries => {
      const total = computeCartTotal(
        {
          selectedOffer: offers.selectedOffer,
          selectedOfferCartEntry: offers.selectedOfferCartEntry,
          selectedOfferPrice: offers.selectedOfferPrice,
        },
        paramCartEntries || cartEntries,
        {
          loyaltyEnabled,
          appliedLoyaltyRewards,
          appliedLoyaltyOfferDiscount: discountAppliedCmsOffers?.incentives?.[0],
          offersEligibleItems,
          loyaltySelectedOffer,
        }
      );
      return {
        cartTotal: total,
        isCartTotalNegative: total < 0,
      };
    },
    [
      appliedLoyaltyRewards,
      cartEntries,
      loyaltyEnabled,
      discountAppliedCmsOffers,
      offersEligibleItems,
      loyaltySelectedOffer,
      offers.selectedOffer,
      offers.selectedOfferCartEntry,
      offers.selectedOfferPrice,
    ]
  );

  const calculateCartTotal = useCallback(
    paramCartEntries => {
      const { cartTotal, isCartTotalNegative } = calculateCartTotalWithDiscount(paramCartEntries);
      return isCartTotalNegative ? 0 : cartTotal;
    },
    [calculateCartTotalWithDiscount]
  );

  const clearCartStoreServiceModeTimeout = useCallback(() => {
    selectServiceMode(null);
    setUnavailableCartEntries([]);
    emptyCart();
    resetStore();
    LocalStorage.removeItem(StorageKeys.LAST_TIME_STORE_UPDATED);
  }, [emptyCart, resetStore, selectServiceMode, setUnavailableCartEntries]);

  const checkoutPriceLimit = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_LIMIT);
  const checkoutDeliveryPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM);
  const checkoutCateringPriceLimit = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_LIMIT);
  const checkoutCateringPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_MINIMUM);

  const [OrderLimitDialog, openOrderLimitDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Order limit reached',
  });

  const alertOrderLimit = useCallback(() => {
    if (pathname.startsWith(routes.cart)) {
      openOrderLimitDialog();
    }
  }, [pathname, openOrderLimitDialog]);

  const updateQuantity = useCallback(
    (cartId, quantity) => {
      if (quantity < 1) {
        return removeFromCart(cartId);
      }

      setCartEntries(prevCartEntries =>
        prevCartEntries.map(entry => {
          if (entry.cartId === cartId) {
            entry.quantity = quantity;
            entry.price = pricingFunction(entry, entry.quantity);
          }

          return entry;
        })
      );
    },
    [pricingFunction, removeFromCart]
  );

  // Updates a single CartEntry
  const updateCartEntry = useCallback(
    (newCartEntry, originalEntry) => {
      // update item in Amplitude cart
      logAddOrRemoveFromCart({
        action: 'remove',
        cartEntry: originalEntry,
        previousCartEntries: [originalEntry],
      });
      logAddOrRemoveFromCart({
        action: 'add',
        cartEntry: newCartEntry,
        previousCartEntries: [originalEntry],
      });

      // If the original entry has a reward applied but the new entry is a different item,
      // then remove the reward.
      const appliedReward = appliedLoyaltyRewards[originalEntry?.cartId];
      if (appliedReward && appliedReward.rewardBenefitId !== getParentIdFromUrl(newCartEntry.url)) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: appliedReward.rewardBenefitId,
            cartId: originalEntry.cartId,
          })
        );
      }

      setCartEntries(prevCartEntries => replaceEntryArrayItem(prevCartEntries, newCartEntry));
      toast.show({
        text: formatMessage({ id: 'updateCartSuccess' }, { itemName: newCartEntry.name }),
        variant: 'positive',
      });
    },
    [appliedLoyaltyRewards, dispatch, formatMessage, logAddOrRemoveFromCart, toast]
  );

  // Updates multiple CartEntries at once
  const updateMultipleCartEntries = useCallback(
    (entriesToUpdate, allOriginalEntries) => {
      entriesToUpdate.forEach(newEntry =>
        updateCartEntry(
          newEntry,
          allOriginalEntries.find(originalEntry => originalEntry.cartId === newEntry.cartId)
        )
      );
    },
    [updateCartEntry]
  );

  // Adds a single cartEntry to the cart
  const addItemToCart = useCallback(
    (cartEntry, eventAttrs, showMessage = true) => {
      setCartEntries(prevCartEntries => {
        logAddOrRemoveFromCart({
          action: 'add',
          cartEntry,
          previousCartEntries: prevCartEntries,
          eventAttrs,
        });
        return prevCartEntries.concat([cartEntry]);
      });

      if (cartEntry.isDonation) {
        return;
      }

      if (showMessage) {
        toast.show({
          testID: TOAST_ITEM_ADDED_TO_CART,
          duration: isTest ? 9999999 : undefined,
          text: formatMessage({ id: 'addToCartSuccess' }, { itemName: cartEntry.name }),
          variant: 'positive',
        });
      }
    },
    [formatMessage, logAddOrRemoveFromCart, toast]
  );

  // Adds multiple cart entries to the cart
  const addMultipleToCart = useCallback(
    ({ newCartEntries, eventAttrs, shouldShowMessage = true }) => {
      newCartEntries.forEach(entry => {
        addItemToCart(entry, eventAttrs, shouldShowMessage);
      });
    },
    [addItemToCart, formatMessage, toast]
  );

  // Adds or Updates CartEntries
  // Accepts CartEntries and will determain which ones are being updated and which ones are new using their cartIds
  const upsertCart = useCallback(
    (newCartEntries, eventAttrs) => {
      // If there are no items in the cart already just add all new entries to the cart
      if (!cartEntries.length) {
        addMultipleToCart({ newCartEntries, eventAttrs });
        return;
      }

      const existingEntries = intersectionBy(newCartEntries, cartEntries, 'cartId');
      const newEntries = differenceBy(newCartEntries, cartEntries, 'cartId');

      addMultipleToCart({ newCartEntries: newEntries, eventAttrs });
      updateMultipleCartEntries(existingEntries, cartEntries);
    },
    [addMultipleToCart, cartEntries, updateMultipleCartEntries]
  );

  const queryOrder = useCallback(
    async rbiOrderId => {
      const { data, errors } = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: GetOrderDocument,
        variables: {
          rbiOrderId,
        },
      });

      if (errors) {
        logger.error({ errors, message: 'Error querying order' });
        throw errors;
      }

      return data?.order;
    },
    [apolloClient]
  );

  // commit was being fired twice, which was firing onCommitSuccess twice
  // the lastPurchaseOrderRId checks if the same order is being committed twice
  // where we set .current = rbiOrderID is on the first run, so the second run will return early
  const lastPurchaseOrderId = useRef();
  const onCommitSuccess = useCallback(
    async remoteOrder => {
      if (lastPurchaseOrderId.current === remoteOrder.rbiOrderId) {
        return;
      }
      if (remoteOrder?.cart?.serviceMode === ServiceMode.CURBSIDE) {
        setCurbsidePickupOrderId('');
        setCurbsidePickupOrderTimePlaced('');
      }
      lastPurchaseOrderId.current = remoteOrder.rbiOrderId;
      emptyCart();
      const cartEntriesData = offers.selectedOfferCartEntry
        ? [...remoteOrder.cart.cartEntries, offers.selectedOfferCartEntry]
        : remoteOrder.cart.cartEntries;
      const quotedFeeCents = remoteOrder.delivery?.quotedFeeCents || 0;
      const deliveryFeeCents = remoteOrder.delivery?.feeCents || 0;
      const deliveryFeeDiscountCents = remoteOrder.delivery?.feeDiscountCents || 0;
      const deliveryGeographicalFeeCents = remoteOrder.delivery?.geographicalFeeCents || 0;
      const deliveryServiceFeeCents = remoteOrder.delivery?.serviceFeeCents || 0;
      const deliverySmallCartFeeCents = remoteOrder.delivery?.smallCartFeeCents || 0;
      const baseDeliveryFeeCents = remoteOrder.delivery?.baseDeliveryFeeCents || 0;
      const deliverySurchargeFeeCents = remoteOrder.delivery?.deliverySurchargeFeeCents || 0;
      logPurchase(cartEntriesData, store, serviceMode, remoteOrder, {
        currencyCode,
        quotedFeeCents,
        baseDeliveryFeeCents,
        totalDeliveryOrderFeesCents:
          baseDeliveryFeeCents +
          deliverySurchargeFeeCents +
          deliveryServiceFeeCents +
          deliverySmallCartFeeCents +
          deliveryGeographicalFeeCents -
          deliveryFeeDiscountCents,
        deliveryFeeCents:
          deliveryFeeCents -
          deliveryFeeDiscountCents -
          deliveryGeographicalFeeCents -
          deliveryServiceFeeCents -
          deliverySmallCartFeeCents,
        deliverySurchargeFeeCents,
        deliveryFeeDiscountCents,
        deliveryGeographicalFeeCents,
        deliveryServiceFeeCents,
        deliverySmallCartFeeCents,
        fireOrderInMinutes: Math.round(fireOrderIn / 60),
      });
      setServerOrder(remoteOrder);

      try {
        await Promise.all([
          selectedOffer ? onConfirmRedemption(selectedOffer) : Promise.resolve(),
          // refresh the user's payment methods stored
          // in state, including gift cards
          getPaymentMethods(),

          // refresh loyalty user points and recent transactions
          loyaltyUserId ? refetchLoyaltyUser() : Promise.resolve(),
          loyaltyUserId ? refetchLoyaltyUserTransaction() : Promise.resolve(),
          // refetch loyalty rewards
          dispatch(actions.loyalty.setShouldRefetchRewards(true)),
        ]);
      } catch (error) {
        logger.error({ error, message: 'Error after commit success' });
      }
    },
    [
      emptyCart,
      offers.selectedOfferCartEntry,
      logPurchase,
      store,
      serviceMode,
      currencyCode,
      fireOrderIn,
      selectedOffer,
      onConfirmRedemption,
      getPaymentMethods,
      refetchGetUserOrders,
      loyaltyUserId,
      refetchLoyaltyUser,
      refetchLoyaltyUserTransaction,
      dispatch,
    ]
  );

  const price = useCallback(
    async paymentMethod => {
      setMark(PerformanceMarks.PRICE_START);

      const pollForPrice = async orderId => {
        if (!pathname.startsWith(routes.cart)) {
          clearTimeout(refPriceOrderTimeout.current);

          return Promise.resolve(null);
        }

        const remoteOrder = await queryOrder(orderId);

        const success = orderPollSuccessful({
          deliverySuccessStatus: DeliveryStatus.QUOTE_SUCCESSFUL,
          isDelivery,
          order: remoteOrder,
          orderSuccessStatus: OrderStatus.PRICE_SUCCESSFUL,
        });
        const failure = orderPollFailure({
          deliveryFailureStatus: [DeliveryStatus.QUOTE_ERROR, DeliveryStatus.QUOTE_UNAVAILABLE],
          isDelivery,
          order: remoteOrder,
          orderFailureStatus: OrderStatus.PRICE_ERROR,
        });

        if (success || failure) {
          setMark(PerformanceMarks.PRICE_END);
        }

        if (success) {
          await offers.validateOfferRedeemable({
            selectedOffer: offers.selectedOffer,
            rbiOrderId: remoteOrder.rbiOrderId,
          });

          setServerOrder(remoteOrder);
          if (isDelivery) {
            const otherDiscountAmount = computeTotalDiscountAmount(remoteOrder.cart.discounts);
            updateTipAmount({ subTotalCents: remoteOrder.cart.subTotalCents, otherDiscountAmount });
          }

          return remoteOrder.status;
        } else if (failure) {
          setServerOrder(remoteOrder || {});

          throw new Error(OrderStatus.PRICE_ERROR);
        } else {
          return new Promise(resolve => {
            refPriceOrderTimeout.current = setTimeout(
              () => resolve(pollForPrice(remoteOrder.rbiOrderId)),
              1000
            );
          });
        }
      };

      // create offer cart item with updated plus
      const cartEntriesWithSelectedOffer = offers.selectedOfferCartEntry
        ? [
            {
              ...offers.selectedOfferCartEntry,
              price: offers.selectedOfferPrice,
              offerVendorConfigs: offers.selectedOffer.vendorConfigs,
              type: `Offer${offers.selectedOfferCartEntry.type}`,
            },
          ].concat(cartEntries)
        : cartEntries;

      const priceInCents = calculateCartTotal(cartEntriesWithSelectedOffer);
      const storeAddress = buildStoreAddress(store);
      const remappedCartEntries = remappedCartForBackEnd(cartEntriesWithSelectedOffer);

      const orderInput = {
        calculateCartTotal,
        cartEntries: cartEntriesWithSelectedOffer,
        cartVersion: CART_VERSION,
        customerLocale,
        customerName: null,
        deliveryAddress,
        orderPhoneNumber,
        paymentMethod,
        redeemReward,
        serviceMode,
        store,
      };

      const deliveryInput = buildPriceDeliveryInput(orderInput, auth.user, quoteId);

      const { data, errors } = await executePriceOrderMutation({
        variables: {
          delivery: deliveryInput,
          input: {
            ...orderInput,
            brand: brand().toUpperCase(),
            storeAddress,
            cartEntries: remappedCartEntries,
            platform: platform(),
            posVendor: store.pos.vendor,
            requestedAmountCents: Math.round(priceInCents),
            storeId: store.number,
            storePosId: store.posRestaurantId,
            appliedOffers,
            vatNumber: store.vatNumber,
          },
        },
      });

      if (errors) {
        logger.error({ errors, message: 'Error pricing order' });
        throw errors;
      }

      const remoteOrder = data.priceOrder;

      if (!remoteOrder) {
        logger.error({ message: `Error pricing order: ${OrderStatus.PRICE_ERROR}` });
        throw new Error(OrderStatus.PRICE_ERROR);
      }
      return pollForPrice(remoteOrder.rbiOrderId);
    },
    [
      offers,
      cartEntries,
      calculateCartTotal,
      store,
      CART_VERSION,
      customerLocale,
      deliveryAddress,
      orderPhoneNumber,
      serviceMode,
      auth.user,
      executePriceOrderMutation,
      appliedOffers,
      quoteId,
      pathname,
      queryOrder,
      isDelivery,
      updateTipAmount,
    ]
  );

  const getAndRefreshServerOrder = useCallback(
    async id => {
      try {
        const order = await queryOrder(id);
        setServerOrder(order || {});
        return order;
      } catch (error) {
        logger.error({ error, message: 'Error refreshing order' });
      }
    },
    [queryOrder]
  );

  const fireOrderInXSeconds = useCallback(
    async ({ rbiOrderId, timeInSeconds }) => {
      const { data, errors } = await executeUpdateOrderMutation({
        variables: {
          input: {
            fireOrderIn: timeInSeconds,
            rbiOrderId,
          },
        },
      });

      if (errors) {
        logger.error({ errors, message: 'Error updating order.' });
        throw errors;
      }

      return getAndRefreshServerOrder(data?.updateOrder?.rbiOrderId);
    },
    [executeUpdateOrderMutation, getAndRefreshServerOrder]
  );

  const clearServerOrder = useCallback(() => {
    setServerOrder({});
  }, []);

  // make sure we persist everything!
  useEffect(() => {
    LocalStorage.setItem(StorageKeys.ORDER, {
      cartEntries,
      cartIdEditing,
      cartVersion: CART_VERSION,
      cateringPickupDateTime,
      deliveryAddress,
      deliveryInstructions,
      quoteId,
      orderPhoneNumber,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
    });
  }, [
    cartEntries,
    cateringPickupDateTime,
    serviceMode,
    deliveryInstructions,
    orderPhoneNumber,
    deliveryAddress,
    curbsidePickupOrderId,
    curbsidePickupOrderTimePlaced,
    cartIdEditing,
    CART_VERSION,
    quoteId,
  ]);

  // Configure the logger to hold some information
  useEffect(() => {
    const storePosVendor = store?.pos?.vendor?.toLowerCase();
    const vendorKey = getPosVendorFromStore(store, serviceMode);

    // recursively remove unused vendorConfigs / null entries and don't mutate the cart
    let cartEntriesStringified = 'UNKNOWN';
    try {
      cartEntriesStringified = JSON.stringify(
        cartEntries,
        (key, value) => {
          if (key === 'image') {
            return undefined; // strip image info (not super useful)
          }

          if (key !== 'vendorConfigs' || !value) {
            return value; // don't touch anything other than vendor config
          }

          if (!storePosVendor) {
            return undefined; // if there isn't a store set just don't return vendor configs
          }

          const vendorConfigObj = value[vendorKey];

          const nullFreeVendorConfig = vendorConfigObj
            ? Object.entries(vendorConfigObj).reduce(
                (a, [k, v]) => (v == null ? a : ((a[k] = v), a)),
                {}
              )
            : vendorConfigObj;
          return { [vendorKey]: nullFreeVendorConfig };
        },
        ' '
      );
    } catch (error) {
      cartEntriesStringified = `UNKNOWN due to ERROR: ${error?.toString()}`;
    }

    const extras = {
      cartEntries: cartEntriesStringified,
      serviceMode,
      storePosVendor,
    };

    if (serverOrder.rbiOrderId) {
      DatadogLogger.addContext('transaction_id', serverOrder.rbiOrderId);
    }

    addLoggingContext({ ...extras, transactionId: serverOrder.rbiOrderId });
  }, [cartEntries, serverOrder.rbiOrderId, serviceMode, store]);

  const isCartEntryInSwaps = useCallback(
    cartEntry =>
      appliedOffers.some(offer =>
        cartEntry?.children?.length
          ? offer.cartId === cartEntry.cartId
          : offer?.swap?.cartId === cartEntry.cartId
      ),
    [appliedOffers]
  );

  // When repricing cart entry, we need a record of parent and main combo in order to price combo item
  const repriceCartEntriesHelper = useCallback(
    (entries, parentEntry, mainCombo) =>
      entries.map(entry => {
        const hasPrice = entry.price !== undefined;
        const isItem = entry.type === CartEntryType.item;
        const isCombo = entry.type === CartEntryType.combo;
        const isSwap = isCartEntryInSwaps(entry);
        const isComboSlot = parentEntry?.type === CartEntryType.comboSlot;

        if (isItem) {
          // reprices an item and its grandchild itemOptionModifiers in one go.
          const priceFunc = isComboSlot
            ? priceForItemInComboSlotSelection({
                combo: mainCombo,
                comboSlot: parentEntry,
                selectedItem: entry,
              })
            : pricingFunction(entry, entry.quantity);
          return {
            ...entry,
            // prices are not defined on main items in combos, we need to preserve this
            ...(!isSwap && hasPrice && { price: priceFunc }),
            children: (entry.children || []).map(itemOption => ({
              ...itemOption,
              children: (itemOption.children || []).map(modifier => ({
                ...modifier,
                price: priceForItemOptionModifier({ item: entry, itemOption, modifier }),
              })),
            })),
          };
        }
        return {
          ...entry,
          ...(!isSwap && isCombo && hasPrice && { price: pricingFunction(entry, entry.quantity) }),
          children: repriceCartEntriesHelper(entry.children, entry, isCombo ? entry : mainCombo),
        };
      }),
    [
      isCartEntryInSwaps,
      pricingFunction,
      priceForItemInComboSlotSelection,
      priceForItemOptionModifier,
    ]
  );

  const repriceCartEntries = useCallback(
    (entries, parentEntry, mainCombo) => {
      const newEntries = repriceCartEntriesHelper(entries, parentEntry, mainCombo);
      // If newEntries is structurally equal to entries, then return original object
      // to avoid unnecessary re-renders. This is a fix to priceOrder mutation being called twice.
      return isEqual(entries, newEntries) ? entries : newEntries;
    },
    [repriceCartEntriesHelper]
  );

  // recursively reprice cartEntries when prices change
  useEffect(() => {
    if ((enableMenuServiceData && storeMenuLoading) || !prices) {
      return;
    }

    setCartEntries(prevEntries => repriceCartEntries(prevEntries));
    // adding repriceCartEntries to the deps array crashes the browser
    // because of its dependency on pricingFunction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, storeMenuLoading]);

  // show error cart price limit modal
  const isCatering = isCateringOrder(serviceMode);
  useAlertOrderLimit({
    calculateCartTotal,
    checkoutPriceLimit,
    checkoutCateringPriceLimit,
    alertOrderLimit,
    isCatering,
    setCartPriceLimitExceeded,
  });

  useAlertOrderCateringMinimum({
    calculateCartTotal,
    checkoutCateringPriceMinimum,
    isCatering,
    setCartCateringPriceTooLow,
  });

  const handleReorder = useHandleReorder({
    addMultipleItemsToCart: addMultipleToCart,
    setPendingReorder,
    setReordering,
    storeHasSelection: isStoreAvailable,
    setUnavailableCartEntries,
    setReorderedOrderId,
    cartEntries,
  });

  const isCartTotalGreaterThanDiscountOfferValue = useCallback(() => {
    const total = calculateCartTotal();
    let discountValue = 0;
    if (selectedOffer) {
      const selectedDiscountOfferType = selectedOffer?.option?.discountType;
      const selectedDiscountOfferValue = selectedOffer?.option?.discountValue || 0;

      discountValue =
        selectedDiscountOfferType === OfferDiscountTypes.AMOUNT ? selectedDiscountOfferValue : 0;
    } else if (discountAppliedCmsOffers?.length) {
      discountValue = discountAppliedCmsOffers.reduce((acc, loyaltyCmsOffer) => {
        const discountIncentive = loyaltyCmsOffer?.incentives?.[0];
        const offerDiscountValue =
          discountIncentive?.discountType === OfferDiscountTypes.AMOUNT
            ? discountIncentive?.discountValue || 0
            : 0;

        return acc + offerDiscountValue;
      }, 0);
    }

    return discountValue > total / 100;
  }, [calculateCartTotal, discountAppliedCmsOffers, selectedOffer]);
  const offerCartEntry = offers.selectedOfferCartEntry || null;
  const cartPreviewEntries = useMemo(
    () => (offerCartEntry ? [offerCartEntry, ...cartEntries] : cartEntries),
    [offerCartEntry, cartEntries]
  );
  const isCartEmpty = !cartPreviewEntries.length;
  const canUserCheckout = !isCartEmpty && !cartPriceLimitExceeded;
  const numCartPreviewEntries = (cartPreviewEntries || []).reduce((accum, entry) => {
    if (entry.isDonation) {
      return accum;
    }

    return accum + (entry.quantity ?? 1);
  }, 0);

  // Prefetch
  const prefetchRestaurantPosData = useCallback(
    restaurant => {
      getPosData({
        restaurantPosDataId: restaurant.restaurantPosData?._id || '',
      });
    },
    [getPosData]
  );

  const selectStore = useCallback(
    async (newStore, callback, requestedServiceMode) => {
      setFetchingPosData(true);
      const selectedStorePrices = await getPosData({
        restaurantPosDataId: newStore.restaurantPosData?._id || '',
      });

      // CartEntries corresponding to Offers can't be checked using its original type
      const mappedCartEntries = (cartEntries || []).map(cartEntry => ({
        ...cartEntry,
        type:
          cartEntry.type === CartEntryType.offerCombo
            ? CartEntryType.combo
            : cartEntry.type === CartEntryType.offerItem
            ? CartEntryType.item
            : cartEntry.type,
      }));

      const unavailableItems = getUnavailableCartEntries(
        mappedCartEntries,
        newStore,
        selectedStorePrices?.posData || prices,
        requestedServiceMode
      );

      const selectNewStoreCallback = () => {
        if (unavailableItems.length) {
          removeAllFromCart(unavailableItems);
        }
        return callback();
      };

      const updateCallback = async () => {
        await selectNewStore({
          sanityStore: newStore,
          hasCartItems: !isCartEmpty,
          unavailableCartEntries: unavailableItems,
          callback: selectNewStoreCallback,
          requestedServiceMode,
          selectedOffer,
        });
        setFetchingPosData(false);
      };

      if (isDelivery) {
        await updateCallback();
      } else {
        updateUserStoreWithCallback(newStore, updateCallback);
      }
    },
    [
      cartEntries,
      getPosData,
      isCartEmpty,
      isDelivery,
      prices,
      removeAllFromCart,
      selectNewStore,
      selectedOffer,
      updateUserStoreWithCallback,
      setFetchingPosData,
    ]
  );

  const [shouldHandleReorder, setShouldHandleReorder] = useState(false);
  useEffect(() => {
    if (!shouldHandleReorder) {
      return;
    }
    // we have to wait for pricing to be complete from selecting a new store before we redirect
    // we also only want to reorder if we haven't already, pendingReorder gets set to null when we are done reordering
    if (isStoreAvailable && prices && pendingReorder) {
      // Pop store locator modal while reordering
      setShouldHandleReorder(false);
      handleReorder(pendingReorder);
    }
  }, [
    handleReorder,
    isStoreAvailable,
    pendingReorder,
    prices,
    reordering,
    setReordering,
    shouldHandleReorder,
  ]);

  useDebouncedEffect(
    function runEligibleItemsEvaluation() {
      if (!loyaltyUserId) {
        return;
      }
      if (appliedLoyaltyOffers.length) {
        evaluateEligibleItems({
          loyaltyId: loyaltyUserId,
          appliedLoyaltyOffers,
          cartEntries,
          appliedRewards,
          selectedOfferSelections,
        });
      }
    },
    250,
    [
      loyaltyUserId,
      appliedLoyaltyOffers,
      evaluateEligibleItems,
      cartEntries,
      appliedRewards,
      selectedOfferSelections,
    ]
  );

  const value = useMemo(
    () => ({
      // state
      cartEntries,
      numCartPreviewEntries,
      cartVersion: CART_VERSION,
      unavailableCartEntries,
      serviceMode,
      serverOrder,
      cartPriceLimitExceeded,
      cartCateringPriceTooLow,
      cartHasRewardEligibleItem,
      // Catering
      cateringPickupDateTime,
      setOrderCateringPickupDateTime,
      // Cart
      checkoutPriceLimit,
      checkoutDeliveryPriceMinimum,
      checkoutCateringPriceLimit,
      checkoutCateringPriceMinimum,
      alertOrderLimit,
      confirmRemoveFromCart,
      removeFromCart,
      removeAllFromCart,
      setUnavailableCartEntries,
      editCart,
      cartIdEditing,
      getCurrentCartEntry,
      addItemToCart,
      upsertCart,
      updateCartEntry,
      updateQuantity,
      calculateCartTotal,
      calculateCartTotalWithDiscount,
      calculateCartTotalWithoutOffers,
      emptyCart,
      tipAmount,
      setTipAmount,
      updateTipAmount,
      tipSelection,
      setTipSelection,
      verifyCartVersion,
      repriceCartEntries,
      shouldShowTipPercentage,
      isCartTotalGreaterThanDiscountOfferValue,
      isCartEmpty,
      cartPreviewEntries,
      canUserCheckout,
      isCartEntryInSwaps,
      // Order
      selectServiceMode,
      clearServerOrder,
      selectStore,
      fetchingPosData,
      clearCartStoreServiceModeTimeout,
      deliveryAddress,
      setDeliveryAddress,
      deliveryInstructions,
      setDeliveryInstructions,
      orderPhoneNumber,
      setOrderPhoneNumber,
      isCatering,
      isDelivery,
      fireOrderIn,
      setFireOrderIn,
      onCommitSuccess,
      tipPercentThresholdCents,
      curbsidePickupOrderId,
      updateShouldSaveDeliveryAddress,
      setCurbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
      setCurbsidePickupOrderTimePlaced,
      setQuoteId,
      quoteId,
      // Server Interactions
      price,
      query: getAndRefreshServerOrder,
      fireOrderInXSeconds,
      reorder: {
        handleReorder,
        reordering,
        pendingReorder,
        setReordering,
        setPendingReorder,
        reorderedOrderId,
        setShouldHandleReorder,
      },
      recent: {
        setPendingRecentItem,
        pendingRecentItem,
        pendingRecentItemNeedsReprice,
        setPendingRecentItemNeedsReprice,
      },
      prefetchRestaurantPosData,
      setCartEntries,
      setCartIdEditing,
      isDonationZeroAmountSelected,
      setIsDonationZeroAmountSelected,
      selectedPickupTip,
      setSelectedPickupTip,
      otherPickupTip,
      pickUpTipsCents,
      pickUpTipsInputError,
      setOtherPickupTip,
    }),
    [
      cartEntries,
      numCartPreviewEntries,
      CART_VERSION,
      unavailableCartEntries,
      serviceMode,
      serverOrder,
      cartPriceLimitExceeded,
      cartCateringPriceTooLow,
      cartHasRewardEligibleItem,
      cateringPickupDateTime,
      checkoutPriceLimit,
      checkoutDeliveryPriceMinimum,
      checkoutCateringPriceLimit,
      checkoutCateringPriceMinimum,
      alertOrderLimit,
      confirmRemoveFromCart,
      removeFromCart,
      removeAllFromCart,
      setUnavailableCartEntries,
      editCart,
      cartIdEditing,
      getCurrentCartEntry,
      addItemToCart,
      upsertCart,
      updateCartEntry,
      updateQuantity,
      calculateCartTotal,
      calculateCartTotalWithDiscount,
      calculateCartTotalWithoutOffers,
      emptyCart,
      tipAmount,
      setTipAmount,
      updateTipAmount,
      tipSelection,
      setTipSelection,
      verifyCartVersion,
      repriceCartEntries,
      shouldShowTipPercentage,
      isCartTotalGreaterThanDiscountOfferValue,
      isCartEmpty,
      cartPreviewEntries,
      canUserCheckout,
      isCartEntryInSwaps,
      selectServiceMode,
      clearServerOrder,
      selectStore,
      fetchingPosData,
      clearCartStoreServiceModeTimeout,
      deliveryAddress,
      deliveryInstructions,
      orderPhoneNumber,
      isCatering,
      isDelivery,
      fireOrderIn,
      onCommitSuccess,
      tipPercentThresholdCents,
      curbsidePickupOrderId,
      updateShouldSaveDeliveryAddress,
      curbsidePickupOrderTimePlaced,
      quoteId,
      price,
      getAndRefreshServerOrder,
      fireOrderInXSeconds,
      handleReorder,
      reordering,
      pendingReorder,
      reorderedOrderId,
      pendingRecentItem,
      pendingRecentItemNeedsReprice,
      prefetchRestaurantPosData,
      isDonationZeroAmountSelected,
      selectedPickupTip,
      setSelectedPickupTip,
      otherPickupTip,
      pickUpTipsCents,
      pickUpTipsInputError,
      setOtherPickupTip,
    ]
  );

  return (
    <OrderContext.Provider value={value}>
      {props.children}

      <OrderLimitDialog
        body={orderLimitMessage(checkoutPriceLimit, checkoutCateringPriceLimit, isCatering)}
        heading={formatMessage({ id: 'orderLimitHeading' })}
      />
      <RemoveItemDialog
        body={removeItemMessage(itemToRm)}
        heading={formatMessage({ id: 'removeItem' })}
      />
    </OrderContext.Provider>
  );
}

export default OrderContext.Consumer;
