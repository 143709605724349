import { Redirect, router, useLocalSearchParams } from 'expo-router';
import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';

import { ActionSheet, tokens } from '@fhs/ui';

import { useCartSubscription } from '../api';
import { PaymentMethodSelection } from '../components/action-payment-method-selection';
import { CartActionPickupTimeSelector } from '../components/cart-action-pickup-time-selector';

import { CheckoutScreen as _CheckoutScreen } from './_checkout';

const onClose = () => router.setParams({ action: undefined });

export function CheckoutScreen() {
  const { cart, isPending } = useCartSubscription({ throwOnError: true });

  const { action } = useLocalSearchParams<{
    action: 'time' | 'service-mode' | 'payment';
  }>();
  const showTimeSelector = action === 'time';
  const showPaymentSelector = action === 'payment';
  const showServiceModeSelector = action === 'service-mode';

  if (isPending) {
    return (
      <View style={styles.page}>
        <ActivityIndicator />
      </View>
    );
  }

  if (!cart) {
    throw new Error('UndetectableCartError');
  }

  if (cart.state === 'INSERT_REQUESTED') {
    return null;
  }
  if (cart.state === 'INSERT_SUCCESSFUL') {
    return <Redirect href={`/order-confirmation/${cart.rbiOrderId}`} />;
  }

  return (
    <>
      <View style={styles.page}>
        <View style={styles.inner}>
          <_CheckoutScreen cart={cart} />
        </View>
      </View>
      {showTimeSelector && (
        <ActionSheet isVisible onClose={onClose}>
          <CartActionPickupTimeSelector onClose={onClose} />
        </ActionSheet>
      )}
      {showServiceModeSelector && (
        <ActionSheet isVisible onClose={onClose}>
          service mode
        </ActionSheet>
      )}
      {showPaymentSelector && (
        <ActionSheet isVisible onClose={onClose}>
          <PaymentMethodSelection onClose={onClose} />
        </ActionSheet>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    borderTopColor: tokens.colors.$black10,
    borderTopWidth: 1,
    flex: 1,
  },
  inner: {
    flex: 1,
    width: '100%',
    maxWidth: 908,
    marginHorizontal: 'auto',
  },
});
