import { Link } from 'expo-router';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';

import { Text, YStack } from '@fhs/ui';
import { IDeliveryFee } from 'generated/graphql-gateway';
import { useStore } from 'state/store/hooks';
import { useUIContext } from 'state/ui';

import { BaseFee } from './base-fee';
import { DELIVERY_DETAILED_FEES, SUPPORTED_BREAKDOWN_FEES } from './constants';
import { GeographicalFee } from './geographical-fee';
import { LongDistanceFee } from './long-distance-fee';
import { getGeoFeeMessage } from './util';

type DeliveryFeeModalProps = {
  deliveryTotalFee?: number;
  deliveryFees: IDeliveryFee[];
  onTermsRedirect: () => void;
};

export const DeliveryFeeDialogContent = ({
  deliveryFees,
  onTermsRedirect,
}: DeliveryFeeModalProps) => {
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale: currencyFormatter } = useUIContext();

  const { storeProxy: store } = useStore();
  const { physicalAddress } = store;

  const breakdownFees = deliveryFees?.filter(fee => SUPPORTED_BREAKDOWN_FEES.includes(fee.name));

  const { baseFee, longDistance, geoFee } = useMemo(
    () => ({
      baseFee: breakdownFees.find(fee => fee.name === DELIVERY_DETAILED_FEES.BASE_FEE),
      longDistance: breakdownFees.find(
        fee => fee.name === DELIVERY_DETAILED_FEES.LONG_DISTANCE_FEE
      ),
      geoFee: breakdownFees.find(fee => fee.name === DELIVERY_DETAILED_FEES.GEOGRAPHICAL_FEE),
    }),
    [breakdownFees]
  );

  const showGeographicalFee = Boolean(geoFee?.totalValueCents);

  return (
    <YStack>
      <BaseFee
        style={styles.feeSection}
        formattedFee={currencyFormatter(baseFee?.totalValueCents || 0)}
      />
      {!!longDistance?.totalValueCents && (
        <LongDistanceFee
          style={styles.feeSection}
          isDynamic
          formattedFee={currencyFormatter(longDistance.totalValueCents)}
        />
      )}
      {showGeographicalFee && (
        <GeographicalFee
          style={styles.feeSection}
          formattedFee={currencyFormatter(geoFee?.totalValueCents || 0)}
          feeExplanationText={getGeoFeeMessage(physicalAddress)}
        />
      )}
      <Text.Paragraph size="sm" style={styles.textItem}>
        {formatMessage(
          { id: 'deliveryFeeFullTermsLink' },
          {
            link: (
              <Link
                href={formatMessage({ id: 'routes.deliveryTerms' })}
                onPress={onTermsRedirect}
                style={styles.link}
              >
                {formatMessage({ id: 'clickHere' })}
              </Link>
            ),
          }
        )}
      </Text.Paragraph>
    </YStack>
  );
};

const styles = StyleSheet.create({
  feeBreakdown: {
    marginVertical: 18,
  },
  textItem: {
    lineHeight: 24,
  },
  link: {
    textDecorationLine: 'underline',
  },
  feeSection: {
    marginBottom: 12,
  },
});
