import { useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { usePathname } from 'hooks/use-pathname';
import logger from 'utils/logger';

interface UseLogOnLongLoadingOptions {
  /** name the human readable name of the thing we are tracking */
  name: string;
  /** isLoading is the @name loading? if is loading is null/undefined we don't log anything */
  logIfLoadingLongerThanMs?: number;
  /** logIfLoadingLongerThanMs - does not support changes after initial hook call */
  isLoading?: boolean | null;
  /** additionalContext any additional context to log to help us figure out whats going on. router.pathname is automatically included */
  additionalContext?: Object;
}
/**
 * log to datadog if @isLoading exceeds @logIfLoadingLongerThanMs . If this happens log to datadog once loading finally completes as well.
 * @param options
 */
export default function useLogOnLongLoading({
  name,
  logIfLoadingLongerThanMs = 10000,
  isLoading,
  additionalContext,
}: UseLogOnLongLoadingOptions) {
  const startTimeTicksRef = useRef(new Date().getTime());
  const instanceCorrelationIdRef = useRef(uuidv4());
  const pathname = usePathname();

  const fullContext = {
    ...additionalContext,
    name,
    isLoading,
    currentRoutePathname: pathname,
    longLoadingCorrelationId: instanceCorrelationIdRef.current,
    logIfLoadingLongerThanMs,
    isLongLoadingEvent: true,
  };

  // Use a ref to access the current value in other closures
  const fullContextRef = useRef(fullContext);
  fullContextRef.current = fullContext;

  // log if isLoading exceeds logIfLoadingLongerThanMs
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!fullContextRef.current.isLoading) {
        return;
      }

      logger.warn({
        message: `[LongLoading] ${name} - still loading after ${logIfLoadingLongerThanMs}ms`,
        context: {
          ...fullContextRef.current,
        },
      });
    }, logIfLoadingLongerThanMs);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // log final loading time in case of exceeding logIfLoadingLongerThanMs
  const finalLoadingTimeRef = useRef<Date>();
  useEffect(() => {
    if (finalLoadingTimeRef.current) {
      return; // already logged if needed to
    }

    if (!isLoading) {
      finalLoadingTimeRef.current = new Date();
    }

    const elapsedMs = new Date().getTime() - startTimeTicksRef.current;
    if (elapsedMs > logIfLoadingLongerThanMs) {
      logger.warn({
        message: `[LongLoadingComplete] ${name} - loaded after ${elapsedMs}ms`,
        context: {
          ...fullContextRef.current,
          isLongLoadingCompleteEvent: true,
          finalLoadingMs: elapsedMs,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
}
