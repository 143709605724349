import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ModalItemUnavailable } from 'components/modal-item-unavailable/modal-item-unavailable';
import { ItemAvailabilityStatus } from 'enums/menu';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { getPosVendorFromStore } from 'hooks/menu/use-pos-vendor';
import useDialogModal, { DialogCb } from 'hooks/use-dialog-modal';
import { useMenuContext } from 'state/menu';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';

export interface IStaticMenuContext {
  resetState: () => void;
  setSelectedStaticMenuItem: Dispatch<
    SetStateAction<{ itemId: string; itemName: string; search: string }>
  >;
  selectedStaticMenuItemName: string;
  selectedStaticMenuItemId: string;
  selectedStaticMenuItemSearch: string;
  OpenItemUnavailableModal: DialogCb<object>;
  checkStaticMenuItemAvailability(
    store: StoreProxy | IRestaurantNode,
    serviceMode?: ServiceMode | null
  ): Promise<ItemAvailabilityStatus>;
}

export const StaticMenuContext = createContext({} as IStaticMenuContext);

export const useStaticMenuContext = () => useContext(StaticMenuContext);

/**
 *
 * Controls showing of static menu and redirect flow for static menu ordering.
 *
 * If a user selects a menu item in the static menu with no store selected
 * the item pathname they selected is stored in "selectedStaticMenuItemId" and they are
 * navigated to store selector.
 *
 * After selection of a store, the user is routed back to the "selectedStaticMenuItemId".
 *
 **/

const DEFAULT_STATE = {
  itemId: '',
  itemName: '',
  search: '',
};

export const StaticMenuProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { checkItemAvailability } = useMenuContext();
  const [{ itemId, itemName, search }, setSelectedStaticMenuItem] = useState(DEFAULT_STATE);

  const [ItemUnavailableModal, OpenItemUnavailableModal] = useDialogModal({
    // @ts-expect-error TS(2322) FIXME: Type 'FC<IUseDialogComponentProps>' is not assigna... Remove this comment to see the full error message
    Component: ModalItemUnavailable,
  });

  const resetState = useCallback(() => {
    setSelectedStaticMenuItem(DEFAULT_STATE);
  }, []);

  const checkStaticMenuItemAvailability = useCallback<
    IStaticMenuContext['checkStaticMenuItemAvailability']
  >(
    async (restaurant, serviceMode) => {
      const vendor = getPosVendorFromStore(restaurant, serviceMode);
      const { availabilityStatus } = await checkItemAvailability({
        vendor,
        restaurantPosDataId: restaurant?.restaurantPosData?._id ?? '',
        itemId,
      });

      if (availabilityStatus !== ItemAvailabilityStatus.AVAILABLE) {
        OpenItemUnavailableModal();
      }
      return availabilityStatus;
    },
    [checkItemAvailability, itemId, OpenItemUnavailableModal]
  );

  const value = useMemo(
    () => ({
      resetState,
      setSelectedStaticMenuItem,
      selectedStaticMenuItemId: itemId,
      selectedStaticMenuItemName: itemName,
      selectedStaticMenuItemSearch: search,
      OpenItemUnavailableModal,
      checkStaticMenuItemAvailability,
    }),
    [
      OpenItemUnavailableModal,
      checkStaticMenuItemAvailability,
      itemId,
      itemName,
      resetState,
      search,
    ]
  );

  return (
    <StaticMenuContext.Provider value={value}>
      {children}
      <ItemUnavailableModal />
    </StaticMenuContext.Provider>
  );
};
