import { PropsWithChildren } from 'react';
import { Platform } from 'react-native';

import { XStack, XStackProps } from '../stack';

type InputContainerProps = {
  onHoverIn?: () => void;
  onHoverOut?: () => void;
  style?: XStackProps['style'];
};

export const InputContainer = ({
  children,
  onHoverIn,
  onHoverOut,
  style,
}: PropsWithChildren<InputContainerProps>) => {
  const webProps = { onMouseEnter: onHoverIn, onMouseLeave: onHoverOut } as any;
  const nativeProps = { onTouchStart: onHoverIn, onTouchEnd: onHoverOut } as any;
  const props = Platform.select({ native: nativeProps, web: webProps });
  return (
    <XStack {...props} style={style}>
      {children}
    </XStack>
  );
};
