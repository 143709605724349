import { PropsWithChildren } from 'react';

import { MqSwitch } from '@fhs/ui';

import { AuthContainerDesktop } from './auth-container-desktop';
import { AuthContainerMobile } from './auth-container-mobile';

export function AuthContainer({ children }: PropsWithChildren) {
  return (
    <MqSwitch
      $ltDesktop={() => <AuthContainerMobile>{children}</AuthContainerMobile>}
      $gteDesktop={() => <AuthContainerDesktop>{children}</AuthContainerDesktop>}
    />
  );
}
