import { IntlShape } from 'react-intl';

import { ISanityVendorConfigs, IWithPricingProps } from '@rbi-ctg/menu';
import { ItemAvailabilityStatus } from 'enums/menu';
import { IRestaurantNode } from 'generated/rbi-graphql';
import { getMenuIdForIncentive } from 'pages/loyalty/loyalty-incentives-components/incentive-details/utils';
import { IDayPartBoundary, IValidDayPart } from 'state/day-part/hooks/use-active-day-parts';
import {
  IIncentiveEvaluationResult,
  IncentiveEvaluationErrorCodes,
} from 'state/loyalty/hooks/types';
import { LoyaltyOffer, LoyaltyReward, isLoyaltyOffer, isReward } from 'state/loyalty/types';
import { isDiscountLoyaltyOffer } from 'state/loyalty/utils';
import { Menu } from 'state/menu';
import { StoreProxy } from 'state/store';
import {
  IMenuItem,
  getMenuItemDayParts,
  getNextMatchingDayPart,
  isAvailableForActiveDayParts,
  itemIsAvailable,
  timeToString,
} from 'utils/availability';
import { PosVendors } from 'utils/vendor-config';

export const isRewardAvailableForVendor = async ({
  reward,
  noStoreSelected,
  isStoreOpenAndAvailable,
  store,
  vendor,
  checkItemAvailability,
}: {
  reward: LoyaltyReward;
  noStoreSelected: boolean;
  isStoreOpenAndAvailable: boolean;
  store: StoreProxy | IRestaurantNode;
  vendor: PosVendors | null;
  checkItemAvailability: Menu['checkItemAvailability'];
}) => {
  // All rewards are assumed available if no store is selected
  if (noStoreSelected || !vendor) {
    return true;
  }

  const itemId = getMenuIdForIncentive(reward);
  if (!itemId || !isStoreOpenAndAvailable) {
    return false;
  }

  const { availabilityStatus } = await checkItemAvailability({
    vendor,
    restaurantPosDataId: store.restaurantPosData?._id ?? '',
    itemId,
  });

  return availabilityStatus === ItemAvailabilityStatus.AVAILABLE;
};

export const isRewardAvailableForActiveDayParts = ({
  reward,
  activeDayParts,
}: {
  reward: LoyaltyReward;
  activeDayParts: IDayPartBoundary[];
}) => {
  if (!reward?.incentives?.length) {
    return true;
  }
  // For now get first incentive, future implementation TBD
  return isAvailableForActiveDayParts({
    menuData: reward.incentives[0] as IMenuItem,
    activeDayParts,
  });
};

export const isOfferAvailableForVendor = ({
  offer,
  prices,
  vendor,
}: {
  offer: LoyaltyOffer;
} & IWithPricingProps) => {
  if (!offer?.vendorConfigs || !prices || !vendor) {
    return true;
  }
  return itemIsAvailable(
    { ...offer, name: undefined, vendorConfigs: offer.vendorConfigs as ISanityVendorConfigs },
    vendor,
    prices
  );
};

export const isOfferAvailableForActiveDayParts = ({
  offer,
  activeDayParts,
}: {
  offer: LoyaltyOffer;
  activeDayParts: IDayPartBoundary[];
}) => {
  if (offer.daypart?.length) {
    const dayPartsSet = new Set(offer.daypart);
    return activeDayParts.some(dayPart => dayPartsSet.has(dayPart.key));
  }
  if (!offer?.incentives?.length || isDiscountLoyaltyOffer(offer)) {
    return true;
  }
  // For now get first incentive, future implementation TBD
  return isAvailableForActiveDayParts({
    menuData: offer.incentives[0] as IMenuItem,
    activeDayParts,
  });
};

export const buildOutOfDayPartEvaluationResult = (
  incentive: LoyaltyOffer | LoyaltyReward,
  dayParts: readonly IValidDayPart[]
): IIncentiveEvaluationResult => ({
  code: IncentiveEvaluationErrorCodes.OUT_OF_DAY_PART,
  ruleId: '',
  message: '',
  currentValue: null,
  targetValue: {
    incentive,
    dayParts,
  },
});

export const buildVendorEvaluationResult = (): IIncentiveEvaluationResult => ({
  code: IncentiveEvaluationErrorCodes.NOT_AVAILABLE_IN_STORE,
  ruleId: '',
  message: '',
  currentValue: null,
  targetValue: null,
});

export const getIncentiveDayParts = (
  incentive: LoyaltyOffer | LoyaltyReward
): LoyaltyOffer['daypart'] => {
  let dayParts: (string | null)[] = [];
  if (isLoyaltyOffer(incentive) && incentive.daypart) {
    dayParts = [...incentive.daypart];
  } else if (isReward(incentive) && incentive.incentives?.length) {
    dayParts.push(...getMenuItemDayParts(incentive.incentives[0] as IMenuItem));
  }
  return dayParts;
};

export const makeIncentiveOutOfDayPartMessage = ({
  incentive,
  dayParts,
  formatters,
}: {
  incentive: LoyaltyOffer | LoyaltyReward;
  dayParts: readonly IValidDayPart[];
  formatters: IntlShape;
}) => {
  let message: string;
  const { formatMessage, formatTime } = formatters;

  const incentiveDayParts = getIncentiveDayParts(incentive);
  const nextActiveDayPart = getNextMatchingDayPart(incentiveDayParts, dayParts);
  if (nextActiveDayPart) {
    message = formatMessage(
      { id: 'incentiveFeedbackOutOfDayPartTime' },
      { time: timeToString(nextActiveDayPart.startTime, formatTime) }
    );
  } else {
    message = formatMessage({ id: 'incentiveFeedbackOutOfDayPart' });
  }

  return message;
};
