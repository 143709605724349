import { Box, Icon, Input, Pressable, Text } from '@fhs-legacy/universal-components';
import { theme } from 'styles/configure-theme';

export const Container = Box.withConfig({
  zIndex: Styles.zIndex.top,
});

export const StyledAddressInput = Input.withConfig({
  fontSize: 'sm',
  boxSize: '$12',
  color: theme.token('text-default'),
  borderColor: theme.token('border-color-input-default'),
  borderWidth: '1',
});

export const StyledInputIcon = Icon.withConfig({
  marginRight: '4',
  color: theme.token('icon-form-input-default'),
});

export const OptionsListWrapper = Box.withConfig({
  backgroundColor: 'white',
  shadow: '2',
  _android: {
    style: {
      elevation: Styles.zIndex.top,
    },
  },
  width: 'full',
  marginTop: '$13.5',
  position: 'absolute',
});

export const StyledOptionsListItem = Pressable.withConfig({
  paddingY: '3',
  paddingX: '4',
  borderWidth: '1',
  boxSize: '12',
  width: 'full',
  borderColor: theme.token('border-color-default'),
  backgroundColor: theme.token('background-input'),
  _pressed: {
    backgroundColor: theme.token('background-default'),
  },
  _hover: {
    backgroundColor: theme.token('background-default'),
  },
  _web: {
    cursor: 'pointer',
    paddingY: 0,
    justifyContent: 'center',
  },
});

export const MainPredictionText = Text.withConfig({
  fontWeight: 'bold',
  font: theme.token('text-style-form-options'),
  paddingRight: '$2',
  _web: {
    paddingRight: 0,
  },
});
export const StyledItemText = Text.withConfig({
  font: theme.token('text-style-form-options'),
});
