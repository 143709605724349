import { Image } from 'expo-image';
import { useLayoutEffect, useMemo } from 'react';
import { FlatList, ListRenderItemInfo, StyleSheet, View } from 'react-native';

import { ActionSheet, Button, IconCartFill, Text, tokens } from '@fhs/ui';
import { EventTypes, useCRMEventsContext } from '@fhs-legacy/frontend/src/state/crm-events';

import { SimplyOfferEventNames } from '../../analytics';
import {
  useAddGuideOfferToCart,
  useEditStepSelectedEntry,
  useOfferCart,
} from '../../state/offer-guide-cart-state';

import { BottomButtonContainer } from './bottom-button';
import { SummaryStepItem, SummaryStepItemProps } from './summary-step-item';

const renderItem = ({ item }: ListRenderItemInfo<SummaryStepItemProps>) => {
  return <SummaryStepItem {...item} />;
};

const renderStepSeparator = () => {
  return <View style={styles.stepSeparator} />;
};

export function Summary() {
  const { logRBIEvent } = useCRMEventsContext();
  const { steps: offerSteps, offerInfo } = useOfferCart();
  const { image, description, termsAndConditions, name } = offerInfo ?? {};
  const { addGuideOfferToCart } = useAddGuideOfferToCart();
  const { editStepSelectedEntry } = useEditStepSelectedEntry();

  useLayoutEffect(() => {
    if (offerSteps.length === 1) {
      addGuideOfferToCart();

      return;
    }
    logRBIEvent({
      name: SimplyOfferEventNames.OFFER_GUIDE_SUMMARY_SHOWN,
      type: EventTypes.Other,
      attributes: {
        offerId: offerInfo?.id,
        sanityId: offerInfo?.cmsId,
        selections: offerSteps.map(step => ({
          key: step.key,
          rank: step.rank,
          selectedEntry: {
            itemId: step.selectedEntry?._id,
            lineId: step.selectedEntry?.cartId,
          },
        })),
      },
    });
  }, []);

  const summaryStepItemProps = useMemo(
    () =>
      offerSteps.map<SummaryStepItemProps>((offerStep, index) => ({
        selectedEntry: offerStep.selectedEntry,
        onPressButton: () => editStepSelectedEntry(offerStep, index),
      })),
    [offerSteps, editStepSelectedEntry]
  );

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.imageContainer}>
        <Image source={{ uri: image }} contentFit="contain" style={{ height: 216 }} />
      </View>
      <ActionSheet.ScrollView>
        <View style={styles.content}>
          <Text.Heading type="one" style={{ textAlign: 'center' }}>
            {name}
          </Text.Heading>
          {description && (
            <Text.Ui size="md" style={styles.subtitle}>
              {description}
            </Text.Ui>
          )}
          <View style={styles.stepsContainer}>
            <FlatList
              data={summaryStepItemProps}
              renderItem={renderItem}
              ItemSeparatorComponent={renderStepSeparator}
            />
          </View>
          <View style={styles.separator} />
          <Text.Paragraph size="sm" style={styles.termAndCondition}>
            {termsAndConditions}
          </Text.Paragraph>
        </View>
      </ActionSheet.ScrollView>
      <BottomButtonContainer>
        <Button size="xl" onPress={addGuideOfferToCart}>
          <Button.Icon>
            <IconCartFill />
          </Button.Icon>
          <Button.Text>Add Offer To Cart</Button.Text>
        </Button>
      </BottomButtonContainer>
    </View>
  );
}

const styles = StyleSheet.create({
  imageContainer: {
    borderWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderColor: tokens.colors.$blackOpacity04,
  },
  content: {
    marginVertical: 24,
    paddingHorizontal: 16,
  },
  subtitle: {
    textAlign: 'center',
    marginTop: 4,
  },
  stepsContainer: {
    marginTop: 20,
    borderWidth: 1,
    borderColor: tokens.colors.$blackOpacity10,
    borderRadius: 8,
  },
  stepSeparator: {
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  separator: {
    marginTop: 20,
    width: '100%',
    height: 1,
    backgroundColor: tokens.colors.$blackOpacity04,
  },
  termAndCondition: {
    color: tokens.colors.$blackOpacity55,
    marginTop: 20,
  },
  buttonContainer: {
    borderTopWidth: 1,
    borderTopColor: tokens.colors.$blackOpacity10,
  },
  button: {
    margin: 16,
  },
});
