import { isUndefined } from 'lodash';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
  IBackendCartEntries,
  IBaseComboSlot,
  IBaseItem,
  ICartEntry,
  ICombo,
  IItem,
  IItemOption,
  IItemOptionModifierWithQuantity,
  IModifierSelection,
  IOffer,
  ISanityItemOptionModifier,
  IWithMenuObjectSettings,
} from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { IPriceOrderParams } from 'hooks/price-order/types';
import { IPriceOrderInput } from 'remote/queries/order';
import { LoyaltyOffer, isLoyaltyOffer } from 'state/loyalty/types';
import { ServiceMode } from 'state/order';
import { StoreProxy } from 'state/store';
import { clamp } from 'utils/calc';
import { buildStoreAddress } from 'utils/cart';
import { brand, platform } from 'utils/environment';
import { getType } from 'utils/menu';
import { makeItemOptionModifier } from 'utils/menu/modifiers';
import { priceForCartEntry } from 'utils/menu/price';
import { getFirstStringInLocaleBlockContent } from 'utils/sanity';

import { PluTypes, PosVendors } from '../vendor-config';

import { redeemReward } from './redeem-reward';
import {
  CartEntryType,
  ComboSlotUiPattern,
  IComboCartEntryOptions,
  IComboSlotCartEntryOptions,
  ICreateCartEntryOptions,
  IItemCartEntryOptions,
  IItemOptionCartEntryOptions,
  IItemOptionModifierCartEntryOptions,
  IOfferCartEntryOptions,
} from './types';

export * from './types';

// This number 9 doesn’t come from Sanity, this is cross brand based on POS limitations on double digit numbers.
// https://rbictg.atlassian.net/browse/CBA-1032
export const MAX_CART_QUANTITY = 9;
export const MIN_CART_QUANTITY = 1;

const clampCartQuantity = clamp(MIN_CART_QUANTITY, MAX_CART_QUANTITY);

export const getMenuObjectLimitPerOrder = (
  menuObject: IWithMenuObjectSettings
): number | undefined => menuObject.menuObjectSettings?.limitPerOrder;

export const getMenuObjectQuantityInCart = (cartEntries?: ICartEntry[], menuObjectId?: string) => {
  if (!cartEntries?.length || !menuObjectId) {
    return 0;
  }
  return cartEntries.reduce(
    (sum, cartEntry) => (cartEntry._id === menuObjectId ? sum + cartEntry.quantity : sum),
    0
  );
};

export const getMenuObjectMaxCartQuantity = (menuObject: IWithMenuObjectSettings): number => {
  const limitPerOrder = getMenuObjectLimitPerOrder(menuObject);
  if (typeof limitPerOrder === 'number' && limitPerOrder >= MIN_CART_QUANTITY) {
    return clampCartQuantity(Math.floor(limitPerOrder));
  }
  return MAX_CART_QUANTITY;
};

export const getMenuObjectCartQuantity = ({
  menuObject,
  quantityToAdd = 1,
  cartEntries,
  cartIdEditing,
}: {
  menuObject?: IWithMenuObjectSettings | null;
  quantityToAdd?: number;
  cartEntries?: ICartEntry[];
  cartIdEditing?: string;
}): {
  availableCartQuantity: number;
  maxCartQuantity: number;
  maxCartQuantityMet: boolean;
} => {
  if (!menuObject) {
    return {
      availableCartQuantity: MAX_CART_QUANTITY,
      maxCartQuantity: MAX_CART_QUANTITY,
      maxCartQuantityMet: false,
    };
  }

  const entries = cartEntries?.filter(entry => entry.cartId !== cartIdEditing);
  const quantityOnCart = getMenuObjectQuantityInCart(entries, menuObject._id);
  const maxCartQuantity = getMenuObjectMaxCartQuantity(menuObject);
  const availableCartQuantity = maxCartQuantity - quantityOnCart;
  const maxCartQuantityMet = quantityToAdd > availableCartQuantity;

  return {
    availableCartQuantity,
    maxCartQuantity,
    maxCartQuantityMet,
  };
};

const StaticIgnoreConfig = Object.values(PosVendors).reduce(
  (acc, vendor) => ({
    ...acc,
    [vendor]: {
      pluType: PluTypes.IGNORE,
    },
  }),
  {}
);

export const frontendToBackendCartEntryMap = ({
  cartId,
  children,
  pickerSelections,
  image,
  sanityId,
  _id,
  uiPattern, // eslint-disable-line @typescript-eslint/no-unused-vars
  labelAsPerPerson, // eslint-disable-line @typescript-eslint/no-unused-vars
  rewardEligible, // eslint-disable-line @typescript-eslint/no-unused-vars
  isUpsell, // eslint-disable-line @typescript-eslint/no-unused-vars
  recommendationToken, // eslint-disable-line @typescript-eslint/no-unused-vars
  recommender, // eslint-disable-line @typescript-eslint/no-unused-vars
  reorder, // eslint-disable-line @typescript-eslint/no-unused-vars
  menuObjectSettings, // eslint-disable-line @typescript-eslint/no-unused-vars
  ...rest
}: ICartEntry): IBackendCartEntries => {
  const remapped: IBackendCartEntries = {
    lineId: cartId,
    // Offer Cart Entries have a sanityId
    // Other entries do not
    sanityId: sanityId || _id,
    children: children.map(frontendToBackendCartEntryMap),
    image: JSON.stringify(image),
    ...rest,
  };

  if (pickerSelections) {
    remapped.pickerSelections = JSON.stringify(pickerSelections);
  }

  return remapped;
};

export const remappedCartForBackEnd = (cart: ICartEntry[]): IBackendCartEntries[] => {
  return cart.map(frontendToBackendCartEntryMap) as IBackendCartEntries[];
};

const getCartEntryForCombo = (combo: ICombo, options?: IComboCartEntryOptions): ICartEntry => {
  const {
    cartId: existingCartId,
    comboSlotSelections = {},
    modifierSelections = [],
    curriedModifierPricingFunction = () => () => 0,
    pricePremiumComboSlot = () => 0,
    pickerSelections = {},
    price,
    quantity,
    pathname: url = '',
    reorder,
    menuObjectSettings,
  } = options ?? {};
  const cartId = existingCartId || uuidv4();
  let children: ICartEntry[] = [];
  const comboSlots = Object.values(comboSlotSelections);

  if (combo.mainItem) {
    // modifiers with no combo slot id correspond to the main item
    const mainItemModifiers = modifierSelections.filter(({ comboSlotId }) =>
      isUndefined(comboSlotId)
    );

    children.push(
      createCartEntry({
        item: combo.mainItem,
        modifierSelections: mainItemModifiers,
        curriedModifierPricingFunction,
      })
    );
  }

  children = [
    ...children,
    ...comboSlots.map(slot =>
      createCartEntry({
        // use the raw data so we have access to all the options, fallback to the selection data just in case(should never hit this case)...
        item: combo.options.find(comboSlot => comboSlot._id === slot.data._id) ?? slot.data,
        selections: slot.selections,
        curriedModifierPricingFunction,
        pricePremiumComboSlot,
        modifierSelections: modifierSelections.filter(
          ({ comboSlotId }) => comboSlotId === slot.data._id
        ),
      })
    ),
  ];

  return {
    _id: combo._id,
    type: CartEntryType.combo,
    quantity: quantity || 1,
    sanityId: '',
    url,
    name: combo.name.locale,
    vendorConfigs: combo.vendorConfigs,
    image: combo.image,
    cartId,
    children,
    pickerSelections,
    menuObjectSettings: combo.menuObjectSettings || menuObjectSettings,
    ...(reorder && { reorder }),
    ...(price && !isNaN(price) && { price }),
  };
};

const getCartEntryForComboSlot = (
  comboSlot: IBaseComboSlot,
  options?: IComboSlotCartEntryOptions
): ICartEntry => {
  const {
    cartId: existingCartId,
    selections,
    pathname: url = '',
    modifierSelections = [],
    curriedModifierPricingFunction = () => () => 0,
    pricePremiumComboSlot = () => 0,
  } = options ?? {};
  const cartId = existingCartId || uuidv4();
  let children: ICartEntry[] = []; // Is this being used at all???

  children = [
    ...children, // Is this being used at all???
    ...(selections || []).map(selection =>
      createCartEntry({
        item: selection.option.option,
        quantity: selection.quantity,
        curriedModifierPricingFunction,
        modifierSelections,
        price: pricePremiumComboSlot(comboSlot, selection.option),
      })
    ),
  ];

  return {
    _id: comboSlot._id,
    type: CartEntryType.comboSlot,
    cartId,
    quantity: 1,
    sanityId: '',
    url,
    name: comboSlot.name.locale,
    vendorConfigs: comboSlot.vendorConfigs,
    uiPattern: comboSlot.uiPattern as ComboSlotUiPattern,
    pickerSelections: {},
    children,
  };
};

const getCartEntryForItem = (
  item: IItem | IBaseItem,
  options?: IItemCartEntryOptions
): ICartEntry => {
  const {
    price,
    quantity,
    cartId: existingCartId,
    pathname: url = '',
    modifierSelections = [],
    curriedModifierPricingFunction = () => () => 0,
    pickerSelections = {},
    reorder,
    menuObjectSettings,
  } = options ?? {};
  const cartId = existingCartId || uuidv4();
  let children: ICartEntry[] = [];

  if (modifierSelections) {
    const itemOpts = modifierSelections.filter(selection => selection.itemId === item._id);
    children = composeItemOptionModifiers(itemOpts).map(selection =>
      // relate the item to the item option modifiers
      createCartEntry({
        item: selection,
        modifierPricingFunction: curriedModifierPricingFunction(item),
      })
    );
    // check if there are modifier required for given item
    // and if there is any default modifier within the list
    if ((item as IItem)?.options?.length > 0) {
      (item as IItem).options.forEach(itemOption => {
        const numberOfModifierAlreadySelected = itemOpts.reduce(
          (prevQuantity, selectedItemOption) => {
            if (selectedItemOption._key === itemOption._key) {
              prevQuantity += selectedItemOption.modifier.quantity;
            }
            return prevQuantity;
          },
          0
        );
        const defaultItemOption = itemOption.options.find(modifierOption => modifierOption.default);
        // we will take the first default modifier and add enough to meet minAmount
        if (
          itemOption.injectDefaultSelection &&
          defaultItemOption &&
          numberOfModifierAlreadySelected < itemOption.minAmount
        ) {
          const defaultItemOptionModifierCartEntry = getCartEntryForItemOptionModifier(
            {
              ...defaultItemOption,
              quantity: itemOption.minAmount - numberOfModifierAlreadySelected,
            },
            {
              modifierPricingFunction: curriedModifierPricingFunction(item),
            }
          );
          const itemOptionCartEntry = getCartEntryForItemOption(itemOption, {
            modifierPricingFunction: curriedModifierPricingFunction(item),
          });
          // will override the item option modifier with the default modifier
          itemOptionCartEntry.children = [defaultItemOptionModifierCartEntry];
          children.push(itemOptionCartEntry);
        }
      });
    }
  }

  return {
    _id: item._id,
    type: CartEntryType.item,
    cartId,
    sanityId: '',
    url,
    name: item.name.locale,
    vendorConfigs: item.vendorConfigs,
    image: item.image,
    quantity: quantity || 1,
    children,
    pickerSelections,
    productHierarchy: item.productHierarchy,
    menuObjectSettings: item.menuObjectSettings || menuObjectSettings,
    ...(reorder && { reorder }),
    ...(price && !isNaN(price) && { price }),
  };
};

const getCartEntryForItemOption = (
  itemOption: IItemOption,
  options?: IItemOptionCartEntryOptions
): ICartEntry => {
  let children: ICartEntry[] = [];
  const {
    cartId: existingCartId,
    pathname: url = '',
    modifierPricingFunction = () => 0,
  } = options ?? {};
  const cartId = existingCartId || uuidv4();

  children = itemOption.options.map(modifier =>
    createCartEntry({
      item: !modifier.multiplier
        ? makeItemOptionModifier(modifier as ISanityItemOptionModifier)
        : modifier, // guarantee our itemOption modifier is always the correct shape
      modifierPricingFunction: (pricingModifier: any) =>
        modifierPricingFunction(itemOption, pricingModifier),
    })
  );

  return {
    _id: itemOption._key,
    name: itemOption.name.locale,
    cartId,
    children,
    quantity: 1,
    vendorConfigs: StaticIgnoreConfig,
    pickerSelections: {},
    sanityId: '',
    type: CartEntryType.itemOption,
    url,
  };
};

const getCartEntryForItemOptionModifier = (
  itemOptionModifier: IItemOptionModifierWithQuantity,
  options?: IItemOptionModifierCartEntryOptions
): ICartEntry => {
  const {
    cartId: existingCartId,
    pathname: url = '',
    modifierPricingFunction = () => 0,
  } = options ?? {};
  const cartId = existingCartId || uuidv4();

  return {
    _id: itemOptionModifier._key,
    cartId,
    sanityId: '',
    children: [],
    type: CartEntryType.itemOptionModifier,
    vendorConfigs: itemOptionModifier.vendorConfigs ?? {},
    name: itemOptionModifier.name?.locale ?? '',
    url,
    price: modifierPricingFunction(itemOptionModifier),
    quantity: (itemOptionModifier.quantity || itemOptionModifier.multiplier) ?? 1,
    pickerSelections: {},
  };
};

const getCartEntryForOfferDiscount = (
  offer: IOffer | LoyaltyOffer,
  options?: IOfferCartEntryOptions
): ICartEntry => {
  const { cartId: existingCartId, pathname: url = '' } = options ?? {};
  const cartId = existingCartId || uuidv4();

  // SystemwideOffers vendorConfigs types definitions are not compatible with IOffers
  // Ideally we should nullify this for SystemwideOffers, but changes on existing types definitions need more analysis
  let vendorConfigs = {};
  if (!isLoyaltyOffer(offer) && offer.vendorConfigs) {
    vendorConfigs = offer.vendorConfigs;
  }

  return {
    _id: offer._id,
    cartId,
    sanityId: '',
    children: [],
    type: CartEntryType.offerDiscount,
    vendorConfigs,
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ readonly __typename?: "LocaleB... Remove this comment to see the full error message
    name: getFirstStringInLocaleBlockContent(offer.name),
    url,
    quantity: 1,
    pickerSelections: {},
  };
};

export const createCartEntry = (options: ICreateCartEntryOptions): ICartEntry => {
  const { item } = options;
  const itemType = getType(item);
  if (!itemType) {
    throw new Error('_type or type is missing on item');
  }

  // TODO: Handle Pickers or remove them as ComboSlot options. ComboSlot options can be pickers, we currently are not handling pickers...
  switch (itemType) {
    case MenuObjectTypes.COMBO:
      return getCartEntryForCombo(item as ICombo, options as IComboCartEntryOptions);
    case MenuObjectTypes.ITEM:
      return getCartEntryForItem(item as IItem | IBaseItem, options as IItemCartEntryOptions);
    case MenuObjectTypes.COMBO_SLOT:
      return getCartEntryForComboSlot(
        item as IBaseComboSlot,
        options as IComboSlotCartEntryOptions
      );
    case MenuObjectTypes.ITEM_OPTION:
      return getCartEntryForItemOption(item as IItemOption, options as IItemOptionCartEntryOptions);
    case MenuObjectTypes.ITEM_OPTION_MODIFIER:
      return getCartEntryForItemOptionModifier(
        item as IItemOptionModifierWithQuantity,
        options as IItemOptionModifierCartEntryOptions
      );
    case 'offer':
    case 'systemwideOffer':
    case 'simplyOffer':
    case 'configOffer':
      return getCartEntryForOfferDiscount(item as IOffer, options as IOfferCartEntryOptions);
    default:
      throw new Error('somehow deriving a type from a non-defined type');
  }
};

const composeItemOptionModifiers = (
  modifierSelections: IModifierSelection[]
): IItemOptionModifierWithQuantity[] => {
  // TODO: At some point we may have itemOptions that are duplicated for a cartEnrty, and will need to add more uniqueness to the keys
  const reducedItemOpts = modifierSelections.reduce((acc, option) => {
    if (acc[option._key]) {
      acc[option._key].options.push(option.modifier);
      return acc;
    }
    const { _key, modifier, ...rest } = option;
    return {
      ...acc,
      [_key]: {
        _key,
        ...rest,
        options: [modifier],
      },
    };
  }, {});

  return Object.values(reducedItemOpts);
};

/**
 * This method will take the url from a cart entry and return the id the entry originated from
 * in the case of a resolved picker option, the id will be the picker's
 * in the case when multiple items are added from a single combo, the id will be the combo's
 *
 *
 * *NOTE* urls can come in two flavors
 * * example url 1 - /menu/picker-$pickerId?cartId=$cartId&_id=$itemId
 * * example url 2 - /menu/picker-$pickerId
 * @param {String} url
 * @returns {String} parentId
 */
export const getParentIdFromUrl = (url: string = ''): string => {
  if (!url) {
    return url;
  }

  const [pathWithParentId] = url.split('?');
  const parentIdIdx = pathWithParentId.lastIndexOf('/') + 1;

  const parentIdWithPrefix = pathWithParentId.slice(parentIdIdx);
  const prefixIdx = parentIdWithPrefix.indexOf('-') + 1;

  const rawParentId = parentIdWithPrefix.slice(prefixIdx);
  return rawParentId;
};

/**
 * Gets a list of selections modifiers/comboSlot items to display to the guest for an entry
 * Will not display the parents only the lowest level selections (combo slots and item options are skipped)
 *
 * Note: This is different from the cart
 * (https://github.com/rbilabs/ctg-whitelabel-app/blob/GBR-510-favorite-cards/workspaces/frontend/src/hooks/use-compose-description.ts)
 *  The cart will group selections by combo slot item and main item displaying them. While this one will skip those completely
 *  without showing them and just list the modifiers/items within them.
 *
 *  Also this cart hook will make and extra menu request which is not needed if you do that before rendering.
 */
export const getSelectionsListFromEntry = (
  entry: ICartEntry | null,
  child?: boolean
): { id: string; element: ReactNode }[] => {
  // Exit early if there is no entry or the entry has no selections
  if (!entry || (!child && !entry.children?.length)) {
    return [];
  }

  // some comboSlots should be hidden from the guest
  if (
    (entry.type === CartEntryType.comboSlot && entry.uiPattern === ComboSlotUiPattern.HIDDEN) ||
    entry.uiPattern === ComboSlotUiPattern.COLLAPSED
  ) {
    return [];
  }

  if (entry.children?.length) {
    return entry.children.flatMap(childEntry => getSelectionsListFromEntry(childEntry, true));
  }

  return [{ id: entry.cartId, element: entry?.name ?? '' }];
};

export const isCartEntryInMenu = <TCartEntry extends IBackendCartEntries>(
  cartEntry: TCartEntry
): boolean => {
  let isInMenu: boolean = cartEntry.isInMenu !== false;

  if (isInMenu && cartEntry.children && cartEntry.children.length !== 0) {
    // it's in the menu if all the children are in the menu too
    isInMenu = cartEntry.children.every(isCartEntryInMenu);
  }

  return isInMenu;
};

export const everyCartEntriesInMenu = <TCartEntry extends IBackendCartEntries>(
  cartEntries: TCartEntry[]
): boolean => !cartEntries.some(cartEntry => !isCartEntryInMenu(cartEntry));

export interface ICartInput {
  cartEntries: ICartEntry[];
  serviceMode: ServiceMode;
  store: StoreProxy;
}

export function buildCartInput(cartInput: ICartInput): IPriceOrderInput {
  const { cartEntries, serviceMode, store } = cartInput;
  return {
    storeAddress: buildStoreAddress(store),
    storeId: String(store.number),
    storePosId: store.posRestaurantId,
    brand: brand().toUpperCase(),
    platform: platform(),
    posVendor: store.pos?.vendor ?? null,
    serviceMode,
    requestedAmountCents: cartEntries.reduce((price, entry) => price + priceForCartEntry(entry), 0),
    cartEntries: remappedCartForBackEnd(cartEntries),
    vatNumber: String(store.vatNumber),
  };
}

type BuildPriceOrderInputParams = Omit<IPriceOrderParams, 'redeemReward'>;

export function buildPriceOrderInput(params: BuildPriceOrderInputParams): IPriceOrderInput {
  return {
    ...buildCartInput(params),
    incentiveSelections: params.incentiveSelections,
    rewardsApplied: params.rewardsApplied,
    cartVersion: params.cartVersion,
    customerLocale: params.customerLocale,
    customerName: params.customerName,
    loyaltyBarcode: params.loyaltyBarcode,
    paymentMethod: params.cardType,
    appliedOffers: params.appliedOffers,
    requestedAmountCents: params.requestedAmountCents,
    redeemReward,
    vatNumber: params.store.vatNumber,
  };
}

export { buildCommitDeliveryInput } from './build-commit-delivery-input';
export { buildCommitOrderInput } from './build-commit-order-input';
export { buildPriceDeliveryInput } from './build-price-delivery-input';
export { buildStoreAddress } from './build-store-address';
