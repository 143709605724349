import { useEffect } from 'react';
import { useControllableState } from '../../hooks';
import { addWithConfig } from '../../utils';
import { Alert } from '../alert';
import { Box } from '../box';
import { HStack } from '../h-stack';
import { Header } from '../header';
import { Icon } from '../icon';
import { IconButton } from '../icon-button';
import { Text } from '../text';
import { VStack } from '../v-stack';

import type { InlineAlertProps } from './types';
import {
  CustomEventNames,
  EventTypes,
  useCRMEventsContext,
} from '@fhs-legacy/frontend/src/state/crm-events';

const iconSize = ['$4', '$6'];

const iconsByStatus = {
  info: <Icon variant="infoFill" size={iconSize} />,
  success: <Icon variant="checkFill" size={iconSize} />,
  // These icons are purposefully set so that the 'warning' status uses the 'errorFill' variant
  // and the 'error' status uses the 'warning' variant. Design wanted it that way.
  warning: <Icon variant="errorFill" size={iconSize} />,
  error: <Icon variant="warning" size={iconSize} />,
} as const;

const backgroundColorByStatus = {
  info: 'token.background-inline-alert',
  success: 'token.background-inline-alert-success',
  warning: 'token.background-inline-alert-warning',
  error: 'token.background-inline-alert-error',
};

const dismissIcon = <Icon variant="cancel" size={iconSize} />;

export const InlineAlert = ({
  action,
  isDismissable = false,
  isVisible: isVisibleProp,
  onVisibilityChange: onVisibilityChangeProp,
  status = 'info',
  title,
  message,
  size,
  iconCentered,
  analyticsHeader,
  ...boxProps
}: InlineAlertProps) => {
  const { logRBIEvent } = useCRMEventsContext();
  const [isVisible, setIsVisible] = useControllableState({
    defaultValue: true,
    value: isVisibleProp,
    handleValueChange: onVisibilityChangeProp,
  });
  const icon = iconsByStatus[status];

  useEffect(() => {
    if (analyticsHeader) {
      logRBIEvent({
        name: CustomEventNames.INLINE_ALERT_DISPLAYED,
        type: EventTypes.Other,
        attributes: {
          inlineAlertHeader: analyticsHeader,
          inlineAlertType: status,
        },
      });
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Alert
      status={status}
      paddingY={size === 'sm' ? ['$2', '$2'] : ['$3', '$4']}
      paddingX={size === 'sm' ? ['$2', '$2'] : ['6%', '$4']}
      backgroundColor={backgroundColorByStatus[status]}
      alignItems="flex-start"
      borderRadius="none"
      {...boxProps}
    >
      <HStack space="$2" alignItems={iconCentered ? 'center' : 'stretch'}>
        <Box alignItems="flex-start">{icon}</Box>
        <VStack space="$1" justifyContent="center" flexShrink="1">
          {title && (
            <Header variant="headerThree" marginBottom="$0">
              {title}
            </Header>
          )}
          <Text variant="copyTwo" marginBottom="$0" fontSize={size === 'sm' ? 11 : 12}>
            {message}
          </Text>
          {action || null}
        </VStack>
        {isDismissable && (
          <Box alignItems="flex-start">
            <IconButton
              variant="ghost"
              icon={dismissIcon}
              onPress={() => setIsVisible(false)}
              marginLeft="auto"
              marginRight="$0"
              padding="$0"
            />
          </Box>
        )}
      </HStack>
    </Alert>
  );
};

export default addWithConfig(InlineAlert);
