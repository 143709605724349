import React, { ComponentProps, VFC, useId } from 'react';

import { Box, Container, Icon, IconButton, Text } from '@fhs-legacy/universal-components';
import LoadingAnimation from 'components/loading-animation';
import { theme } from 'styles/configure-theme';

interface IStoreActionsButtonProps {
  disabled?: boolean;
  icon: ComponentProps<typeof Icon>['variant'];
  loading?: boolean;
  onPress?: () => void;
  text: string;
  testID?: string;
}

export const StoreActionsButton: VFC<IStoreActionsButtonProps> = ({
  disabled = false,
  onPress = () => {},
  icon,
  text,
  loading = false,
  testID,
}) => {
  const storeActionButtonId = useId();

  return (
    <Container testID="store-action-button-container" alignItems="center" marginY={0} marginX={4}>
      {loading ? (
        <Box
          size="$13.5"
          justifyContent="center"
          alignContent="center"
          borderRadius="full"
          position="relative"
          bg={theme.token('background-button-primary-default')}
        >
          <LoadingAnimation size="sm" reversed />
        </Box>
      ) : (
        <IconButton
          variant="unstyled"
          testID={testID}
          accessibilityLabel={text}
          disabled={loading || disabled}
          id={storeActionButtonId}
          onPress={onPress}
          backgroundColor={
            loading || disabled
              ? theme.token('background-checkbox-disabled')
              : theme.token('background-button-primary-default')
          }
          borderRadius="full"
          position="relative"
          boxSize="$13.5"
          alignItems="center"
          justifyContent="center"
          hitSlop={10}
          padding="0"
          _icon={{ size: '9' }}
          icon={
            <Icon
              variant={icon}
              color={disabled ? theme.token('text-disabled') : theme.token('icon-button-primary')}
            />
          }
          _web={{
            _hover: {
              cursor: 'pointer',
              backgroundColor: theme.token('background-button-primary-hover'),
            },
          }}
          _pressed={{
            backgroundColor: theme.token('background-button-primary-hover'),
            style: {
              transform: [{ scale: 0.95 }],
            },
          }}
        />
      )}

      <Text
        fontFamily="heading"
        testID="store-action-text"
        color={loading || disabled ? theme.token('text-disabled') : theme.token('text-default')}
        marginTop="$1"
        marginBottom="$2"
        fontSize="md"
      >
        {text}
      </Text>
    </Container>
  );
};
