import { Path, Svg } from 'react-native-svg';

import { type IconProps } from '../types';

export const IconCalendarNextFill = (props: IconProps) => {
  const { size = 41, ...otherProps } = props;

  return (
    <Svg width={size} height={size} viewBox="0 0 41 40" fill="none" {...otherProps}>
      <Path
        d="M25.548 35.833v-2.5h6.106a.49.49 0 00.352-.16.49.49 0 00.16-.353V17.18H8.834v6.57h-2.5V10.513c0-.842.292-1.555.875-2.138.584-.583 1.296-.875 2.138-.875h2.308V3.975h2.564V7.5h12.628V3.975h2.5V7.5h2.308c.842 0 1.554.292 2.138.875.583.583.875 1.296.875 2.138V32.82c0 .842-.292 1.555-.875 2.138-.584.584-1.296.875-2.138.875h-6.106zm-11.715 3.574l-1.74-1.74 4.692-4.75H2.583v-2.5h14.202l-4.692-4.75 1.74-1.74 7.74 7.74-7.74 7.74z"
        fill="#01000B"
      />
    </Svg>
  );
};
