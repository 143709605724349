import { StyleSheet, View } from 'react-native';

import { IconShoppingBag, tokens } from '@fhs/ui';
import { Text } from '@fhs/ui';

export function CartEmptyState() {
  return (
    <View style={styles.container}>
      <IconShoppingBag size={80} color={tokens.colors.$blackOpacity10} />
      <Text.Ui size="lg" weight="semibold" style={styles.text}>
        Your cart is empty
      </Text.Ui>
      <Text.Ui size="md" style={styles.text}>
        Explore our delicious menu to find the perfect options to add to your cart!
      </Text.Ui>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
  },
  container: {
    paddingHorizontal: 32,
    gap: 8,
    flex: 1,
    minHeight: 372,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
