import { useCallback } from 'react';

import { useMutation } from '@fhs/client';
import { useConfigValue } from '@fhs-legacy/frontend/src/hooks/configs/use-config-value';
import { useAuthContext } from '@fhs-legacy/frontend/src/state/auth';
import { getGooglePaymentsConfigs } from '@fhs-legacy/frontend/src/state/google-pay/get-google-payments-configs';
import useGooglePay from '@fhs-legacy/frontend/src/state/google-pay/hooks/use-google-pay';

import { commitOrder, useCart } from '../api';

import { usePaymentAdapter } from './payments';
import { PaymentMethodType } from './types';

export function usePaymentProcessor() {
  const { selectedPaymentMethod } = usePaymentAdapter();
  const { cart } = useCart();
  const { requestGooglePayPayment, requestingPayment } = useGooglePay({
    rbiOrderId: cart?.rbiOrderId ?? '',
  });
  const { isPending: committingOrder, mutate: commit } = useMutation(commitOrder);
  const auth = useAuthContext();
  const googleConfig = useConfigValue({ key: 'google', defaultValue: {}, isRegionalized: true });
  const billingCountry = (auth.user?.details?.isoCountryCode as ISOs) || ISOs.USA;
  const userDetailsName = auth?.user?.details?.name ?? '';

  const makePaymentAndCommit = useCallback(async () => {
    assert(selectedPaymentMethod, 'No payment method found.');
    assert(cart, 'Cart not found');

    const total = cart.metadata.pricingData?.totalCents;
    assert(total, 'Cart Total not fund');

    switch (selectedPaymentMethod.type) {
      // Apple Pay
      case PaymentMethodType.APPLE_PAY:
        throw new Error('unsupported');

      // Google pay
      case PaymentMethodType.GOOGLE_PAY:
        // Get the checkout gateway configs for google pay
        const { countryCode, currencyCode } = getCountryAndCurrencyCodes(billingCountry);
        const { gateway, gatewayMerchantId } = getGooglePaymentsConfigs({
          transactionType: TransactionType.PAYMENT,
          countryCode,
          googleConfig,
          migrationEnabled: false,
        });

        // This will prompt the OS to request google pay from the user
        // and return with the payment data that is used on the backend
        // to process the payment
        const googlePayDetails = await requestGooglePayPayment({
          countryCode,
          currencyCode,
          total,
          gateway,
          gatewayMerchantId,
        });

        assert(googlePayDetails, 'No google pay details found');

        commit({
          payment: {
            fullName: userDetailsName,
            googlePayDetails,
          },
        });
        break;

      // Worldpay stored payment method
      case PaymentMethodType.SCHEME:
        commit({
          payment: {
            worldpayInput: {
              storedPaymentMethodId: selectedPaymentMethod.id,
            },
            fullName:
              'fullName' in selectedPaymentMethod
                ? selectedPaymentMethod.fullName
                : userDetailsName,
          },
        });
        break;
    }
  }, [
    cart,
    billingCountry,
    commit,
    userDetailsName,
    googleConfig,
    selectedPaymentMethod,
    requestGooglePayPayment,
  ]);

  return { isPending: requestingPayment || committingOrder, makePaymentAndCommit };
}

//--------------------------------
// private functions

enum ISOs {
  USA = 'USA',
  CAN = 'CAN',
  US = 'USA',
  CA = 'CAN',
}

enum PaymentISOs {
  US = 'US',
  CA = 'CA',
}

enum Currencies {
  USD = 'USD',
  CAD = 'CAD',
}

enum TransactionType {
  PAYMENT = 'payment',
  PREPAID = 'prepaid',
}

function getCountryAndCurrencyCodes(billingCountry: ISOs): {
  countryCode: PaymentISOs;
  currencyCode: Currencies;
} {
  const defaultCodes = {
    countryCode: PaymentISOs.US,
    currencyCode: Currencies.USD,
  };
  return (
    {
      [ISOs.USA]: { countryCode: PaymentISOs.US, currencyCode: Currencies.USD },
      [ISOs.CAN]: { countryCode: PaymentISOs.CA, currencyCode: Currencies.CAD },
    }[billingCountry] || defaultCodes
  );
}

function assert<T>(
  thing: T,
  message: string
): asserts thing is Exclude<T, undefined | null | void> {
  if (!thing) {
    throw new Error(message);
  }
  thing!;
}
