import { inflate } from 'pako';
import { useCallback } from 'react';

import { useQuery, useQueryClient } from '@fhs/client';
import { useNetworkContext } from 'state/network';
import { useServiceModeContext } from 'state/service-mode';
import logger from 'utils/logger';
import { parseStringifiedJSON } from 'utils/parse-string';

const STALE_TIME = 1000 * 60 * 15; // 15 minutes

//https://developer.mozilla.org/en-US/docs/Glossary/Base64
function base64ToBytes(base64: string) {
  if ('fromBase64' in Uint8Array) {
    // @ts-ignore fromBase64 is of type 'unknown'
    // safari and firefox support this and it's the recommended way going forward, chrome doesn't
    return Uint8Array.fromBase64(base64);
  }
  const binString = atob(base64);
  return Uint8Array.from(binString, (m: string): number => m.codePointAt(0) as number);
}

const transformResponse = ({
  base64Plus,
}: {
  base64Plus: string;
}): {
  posData: Record<string, number> | null;
} => {
  if (!base64Plus) {
    return { posData: null };
  }
  const plus = inflate(base64ToBytes(base64Plus), { to: 'string' });
  return {
    posData: parseStringifiedJSON({ defaultValue: {}, value: plus }),
  };
};

function useGetQueryKey() {
  const {
    sanityEndpoints: { groq: groqEndpoint },
  } = useNetworkContext();
  const { isDelivery } = useServiceModeContext();
  return useCallback(
    ({ restaurantPosDataId }: { restaurantPosDataId: string | null }) => {
      return [groqEndpoint, 'posData', restaurantPosDataId, isDelivery] as const;
    },
    [groqEndpoint, isDelivery]
  );
}

export function useGetPosData() {
  const queryClient = useQueryClient();
  const getPosDataQueryKey = useGetQueryKey();

  return useCallback(
    ({ restaurantPosDataId }: { restaurantPosDataId: string | null }) => {
      return queryClient.ensureQueryData({
        queryKey: getPosDataQueryKey({ restaurantPosDataId }),
        queryFn,
        staleTime: STALE_TIME,
      });
    },
    [getPosDataQueryKey, queryClient]
  );
}

async function queryFn({
  queryKey,
  signal,
}: {
  queryKey: ReturnType<ReturnType<typeof useGetQueryKey>>;
  signal?: AbortSignal;
}) {
  const [endpoint, _, posDataId, _isDelivery] = queryKey;
  if (__DEV__ && (!endpoint || !posDataId)) {
    logger.warn(
      `pos data query missing required key: endpoint: ${endpoint}, posDataId: ${posDataId}`
    );

    return { posData: null };
  }
  const url = new URL(endpoint);

  url.searchParams.set(
    'query',
    `*[_id == '${posDataId}'][]{
      'base64PLUs': ${_isDelivery ? 'coalesce(base64DeliveryPLUs, base64PLUs)' : 'base64PLUs'}
    }`
  );
  const response = await fetch(url, { signal });

  const { result } = await response.json();

  return transformResponse({ base64Plus: result?.[0]?.base64PLUs });
}

export const usePosDataQuery = ({
  restaurantPosDataId,
}: {
  restaurantPosDataId: string | null;
}) => {
  const result = useQuery({
    queryKey: useGetQueryKey()({ restaurantPosDataId }),
    queryFn,
    enabled: Boolean(restaurantPosDataId),
    staleTime: STALE_TIME,
  });

  return result;
};
