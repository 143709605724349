import { createContext, useContext } from 'react';
import { Platform } from 'react-native';

import { create } from '@fhs/zustand';

import { mediaQueryTests } from './media-query-tests';
import type { MediaQueriesStoreState, UseMediaQueries } from './types';

const getMediaQueryState = () => {
  const state = {};

  for (const [query, mqList] of Object.entries(mediaQueryTests)) {
    state[query] = mqList.matches;
  }

  return state as MediaQueriesStoreState;
};

const defaultMediaQueries = create<MediaQueriesStoreState>(() => getMediaQueryState());

export const MediaQueriesContext = createContext(defaultMediaQueries);

export const useMediaQueries: UseMediaQueries = <U>(
  selector?: (state: MediaQueriesStoreState) => U
) => {
  const store = useContext(MediaQueriesContext);
  return selector ? store(selector) : store();
};

if (Platform.OS === 'web') {
  Object.entries(mediaQueryTests).forEach(([query, mqList]) => {
    mqList.addEventListener(
      'change',
      () => {
        defaultMediaQueries.setState({ [query]: mqList.matches });
      },
      { passive: true }
    );
  });
} else {
  Object.entries(mediaQueryTests).forEach(([query, mqList]) => {
    // the polyfill only adds the addListener method: https://github.com/expo/match-media/blob/master/src/MediaQueryList.ts
    // @ts-ignore
    mqList.addListener(() => {
      defaultMediaQueries.setState({ [query]: mqList.matches });
    });
  });
}
