import { v4 as uuidv4 } from 'uuid';

import type {
  ICartEntry,
  ICartEntryLegacyOffer,
  ICartEntryOffer,
  ICartEntryReward,
  ICreateCartEntryLegacyOfferParams,
  ICreateCartEntryOfferParams,
  ICreateCartEntryOptions,
  ICreateCartEntryParams,
  ICreateCartEntryRewardParams,
  IEngineRewardCartIncentive,
  ILegacyOfferCartIncentive,
  IOfferCartIncentive,
  IRewardCartIncentive,
} from './types';
import { ICartEntryType, isEngineReward, isLegacyOffer, isOffer, isReward } from './types';

// helper functions
const isRewardEntryParam = (
  item: any
): item is { reward: IRewardCartIncentive; engineReward: IEngineRewardCartIncentive } =>
  isReward(item?.reward) && isEngineReward(item?.engineReward);
const isOfferEntryParam = (item: any): item is { offer: IOfferCartIncentive } =>
  isOffer(item?.offer);
const isLegacyOfferEntryParam = (item: any): item is { offer: ILegacyOfferCartIncentive } =>
  isLegacyOffer(item?.offer);

export function createCartEntry(
  item: ICreateCartEntryRewardParams,
  options?: ICreateCartEntryOptions
): ICartEntryReward;
export function createCartEntry(
  item: ICreateCartEntryOfferParams,
  options?: ICreateCartEntryOptions
): ICartEntryOffer;
export function createCartEntry(
  item: ICreateCartEntryLegacyOfferParams,
  options?: ICreateCartEntryOptions
): ICartEntryLegacyOffer;
export function createCartEntry(
  item: ICreateCartEntryParams,
  options?: ICreateCartEntryOptions
): ICartEntry | null;
export function createCartEntry(
  item: ICreateCartEntryParams,
  { initialQuantity = 1 }: ICreateCartEntryOptions = {}
): ICartEntry | null {
  let partialCartEntry: ICartEntry | null = null;

  if (isOfferEntryParam(item)) {
    partialCartEntry = {
      referenceId: uuidv4(),
      quantity: initialQuantity,
      type: ICartEntryType.OFFER,
      details: { offer: item.offer },
    };
  } else if (isLegacyOfferEntryParam(item)) {
    partialCartEntry = {
      referenceId: uuidv4(),
      quantity: initialQuantity,
      type: ICartEntryType.LEGACY_OFFER,
      details: { offer: item.offer },
    };
  } else if (isRewardEntryParam(item)) {
    partialCartEntry = {
      referenceId: uuidv4(),
      quantity: initialQuantity,
      type: ICartEntryType.REWARD,
      details: { reward: item.reward, engineReward: item.engineReward },
    };
  }

  return partialCartEntry;
}
