import { Link } from 'expo-router';
import { StyleSheet, View, type ViewProps } from 'react-native';

import { useMenuClickEvents } from '@fhs/menu/src/analytics';

import { ProductListTile } from '../product-list-tile';

import type { ItemType, TileSizeType } from './types';

export type SectionListItemProps = {
  tileSize: TileSizeType;
  style?: ViewProps['style'];
  item: ItemType;
};

export const SectionListItem = ({ tileSize, item, style }: SectionListItemProps) => {
  const { logMenuItemClick } = useMenuClickEvents();

  return (
    <View style={[containerStyles.container, style]}>
      <Link href={item.href} asChild>
        <ProductListTile
          onPress={() => {
            logMenuItemClick(item.title);
          }}
          size={tileSize}
          image={item.image}
          title={item.title}
          description={item.description}
          badge={item.badge}
          isAvailable={item.isAvailable}
        />
      </Link>
    </View>
  );
};

const containerStyles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1,
  },
});
