import '@expo/match-media';
import { matchMedia as mockMatchMedia } from 'mock-match-media';

// New breakpoints to use going forward
const desktopMinWidth = 1024;
const desktopLgMinWidth = 1280;

// lt stands for "less than"
// gte stands for "greather than or equal to"

const matchMedia = typeof window === 'undefined' ? mockMatchMedia : window.matchMedia;

export const mediaQueryTests = {
  $base: matchMedia('(min-width: 0px)'),

  // New breakpoints with design <-> engineering agreement
  $ltDesktop: matchMedia(`(max-width: ${desktopMinWidth - 1}px)`),
  $gteDesktop: matchMedia(`(min-width: ${desktopMinWidth}px)`),
  $ltDesktopLg: matchMedia(`(max-width: ${desktopLgMinWidth - 1}px)`),
  $gteDesktopLg: matchMedia(`(min-width: ${desktopLgMinWidth}px)`),
} as const;
