import React from 'react';

import { Box, Link, Text } from '@fhs-legacy/universal-components';
import Picture from 'components/picture';

import { useStaticContentLanguage } from './static-content-language';
import theme from './theme';

const ImageContainer = Box.withConfig({
  width: 'full',
  justifyContent: 'center',
  alignItems: 'center',
  paddingY: '$6',
});

const Image = Picture.withConfig({
  width: '5/6',
  borderRadius: Styles.borderRadius,
  skipBreakpoints: true,
});

const ImageCaption = Text.withConfig({
  paddingTop: '$4',
  textAlign: theme.imageWidgetCaptionAlign,
});

const ImageCaptionLink = Link.withConfig({
  color: Styles.color.primary,
  marginRight: '$2',
  fontSize: 'sm',
});

export default function ImageWidget(props) {
  const { staticContentLanguage: language } = useStaticContentLanguage();

  return (
    <ImageContainer>
      <Image image={props.image?.[language]} alt={props.caption?.[language] ?? ''} />
      {props.caption && (
        <ImageCaption>
          {props.attributionLink && ( // TODO: RN - ensure styling is correct
            <ImageCaptionLink href={encodeURI(props.attributionLink)}>
              Image Source
            </ImageCaptionLink>
          )}
          {props.caption?.[language]}
        </ImageCaption>
      )}
    </ImageContainer>
  );
}
