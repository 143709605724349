import { Link } from 'expo-router';
import { ComponentPropsWithoutRef } from 'react';

import {
  HeaderFrame,
  HeaderIconButton,
  HeaderTitleText,
  IconChevronLeft,
  IconClose,
} from '@fhs/ui';

type GetScreenTitleHeaderOptions = {
  backStyle?: 'back' | 'close' | 'dynamic';
  testID?: string;
  cannotGoBackHref?: ComponentPropsWithoutRef<typeof Link>['href'];
  shouldBackToRoot?: boolean;
};
export const getScreenTitleHeader =
  (getScreenTitleHeaderOpts?: GetScreenTitleHeaderOptions) =>
  ({
    options,
    navigation,
  }: {
    options: { title?: string };
    navigation: { canGoBack: () => boolean };
  }) => {
    const {
      backStyle = 'dynamic',
      cannotGoBackHref = '/',
      testID,
      shouldBackToRoot = false,
    } = getScreenTitleHeaderOpts ?? {};
    const canGoBack = navigation.canGoBack();
    const href = canGoBack && !shouldBackToRoot ? '../' : cannotGoBackHref;
    const icon =
      (backStyle === 'dynamic' && canGoBack) || backStyle === 'back' ? (
        <IconChevronLeft testID={testID} />
      ) : (
        <IconClose testID={testID} />
      );

    return (
      <HeaderFrame>
        <HeaderFrame.Left>
          <Link href={href} asChild dismissTo>
            <HeaderIconButton aria-label="Go back">{icon}</HeaderIconButton>
          </Link>
        </HeaderFrame.Left>
        <HeaderFrame.Middle>
          <HeaderTitleText text={options.title} />
        </HeaderFrame.Middle>
        <HeaderFrame.Right />
      </HeaderFrame>
    );
  };
