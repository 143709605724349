import { router } from 'expo-router';
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Box, Text } from '@fhs-legacy/universal-components';
import ActionButton from 'components/action-button';
import Modal, { ModalContent } from 'components/modal';
import OrderIssues from 'pages/order-confirmation/order-issues';
import { useCRMEventsContext } from 'state/crm-events';
import { ErrorDialogViewComponentNames } from 'state/crm-events/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { theme } from 'styles/configure-theme';
import logger from 'utils/logger';
import { routes } from 'utils/routing';

import HeadingContainer from './cart-error-heading-wrapper';

const ButtonGutter = Box.withConfig({
  flexDirection: { base: 'column', desktop: 'row' },
  justifyContent: { base: 'center', desktop: 'space-between' },
});

const Body = Text.withConfig({
  marginX: 0,
  marginTop: 2,
  marginBottom: 8,
  textAlign: 'justify',
});

const Disclaimer = Text.withConfig({
  variant: 'copyTwo',
  maxWidth: '$64',
  marginTop: 8,
  textAlign: 'center',
});

interface ICartError {
  title?: string;
  body?: ReactNode;
  disclaimer?: string;
  actionButtons?: React.ReactNode;
  onDismiss?: () => void;
  additionalLoggingContext: Object;
}

const message = 'Cart Error: Order could not be completed';

const CartError: React.FC<React.PropsWithChildren<ICartError>> = ({
  title,
  body,
  actionButtons,
  disclaimer,
  onDismiss,
  additionalLoggingContext,
}) => {
  const isShowingOrderingIssue = useFlag(LaunchDarklyFlag.SHOW_ORDERING_ISSUE);
  const { formatMessage } = useIntl();
  const { logErrorDialogView } = useCRMEventsContext();

  const modalHeading = title || formatMessage({ id: 'restaurantUnavailableErrorTitle' });
  const modalBody = body || formatMessage({ id: 'orderNotCompleted' });

  // log once on mount to keep better track of these backend errors preventing checkout
  useEffect(() => {
    logger.error({
      message,
      context: {
        ...additionalLoggingContext,
        guestMessageTitle: title,
        isOrderCouldNotBeCompletedError: true,
      },
    });

    logErrorDialogView(ErrorDialogViewComponentNames.CART_ERROR_MODAL, message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      onDismiss={onDismiss}
      mParticleEventData={{
        modalAppearanceEventMessage: message,
        modalMessage: typeof modalBody === 'string' ? modalBody : undefined,
      }}
      backgroundColor={theme.token('background-default')}
    >
      <ModalContent alignItems="center">
        <HeadingContainer modalHeading={modalHeading} />
        <Body>{modalBody}</Body>

        {actionButtons ? <ButtonGutter>{actionButtons}</ButtonGutter> : null}
        {disclaimer ? <Disclaimer>{disclaimer}</Disclaimer> : null}

        {isShowingOrderingIssue && <OrderIssues onSupportClick={onDismiss} />}
      </ModalContent>
    </Modal>
  );
};

export default CartError;

// This functionality is used in multiple places. Exported here to be passed in as an action button to <CartError /> as convenience
export function SelectNewRestaurantActionButton({ onDismiss = () => {} }) {
  const { formatMessage } = useIntl();

  const handleSelectNewRestaurantPress = useCallback(() => {
    // Close the Cart so the CartContext can sync with the OrderContext
    onDismiss();
    router.navigate({
      pathname: routes.storeLocator,
      params: { back: routes.cart },
    });
  }, []);

  return (
    <ActionButton onPress={handleSelectNewRestaurantPress} testID="select-new-restaurant" mb="$6">
      {formatMessage({ id: 'selectNewRestaurantButton' })}
    </ActionButton>
  );
}
