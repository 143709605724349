import { router, useLocalSearchParams } from 'expo-router';
import { Maybe } from 'graphql/jsutils/Maybe';
import { keyBy } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import LoadingAnimation from 'components/loading-animation';
import { useRumPageView } from 'hooks/rum/use-rum-page-view';
import { useFeatureLoyaltyRewardListCategories } from 'hooks/use-feature-loyalty-reward-list-categories';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useRewardsFlow } from 'hooks/use-rewards-flow';
import NotFound from 'pages/not-found';
import { useAuthContext } from 'state/auth';
import { CustomEventNames, EventTypes, useCRMEventsContext } from 'state/crm-events';
import { useRewardsEvaluation } from 'state/loyalty/hooks/use-loyalty-rewards-evaluation';
import { routes } from 'utils/routing';

import LoyaltyIncentiveNotAvailableModal from '../loyalty-incentives-components/loyalty-incentive-not-available-modal';
import { LoyaltyNotEnrolled } from '../loyalty-not-enrolled';

import { SHOULD_EXPAND_CATEGORIES } from './constants';
import { LoyaltyLoaderContainer } from './loyalty-rewards.styled';
import { LoyaltyRewardsView } from './loyalty-rewards.view';
import { NonNullableRewardCategory } from './types';

// TODO: remove this code for brands with SHOULD_EXPAND_CATEGORIES === false
/**
 * Determines the category that should be open by default based on the passed pre-selections
 * @param rewardCategories filtered categories
 * @param preselectedCategoryLabel pre-selected category label taken from the current route
 * @param preselectedRewardId pre-selected reward id taken from the current route
 */
export const getRewardCategoriesDefaultSelection = (
  rewardCategories: NonNullableRewardCategory[],
  preselectedCategoryLabel?: string | null,
  preselectedRewardId?: string
): Maybe<NonNullableRewardCategory> => {
  let selectedCategory;
  let selectedRewardParentCategory;
  for (const category of rewardCategories) {
    if (preselectedCategoryLabel === category.label?.en) {
      selectedCategory = category;
    }
    for (const reward of category.rewards ?? []) {
      if (preselectedRewardId === reward?._id) {
        selectedRewardParentCategory = category;
      }
    }
  }

  return selectedRewardParentCategory || selectedCategory || rewardCategories?.find(Boolean);
};

const useFilteredRewardCategories = () => {
  const { data: baseCategories } = useFeatureLoyaltyRewardListCategories();
  const { sanityRewardsMap, engineRewardsMap } = useLoyaltyRewardsList();
  const rewardCategories = useMemo(
    () =>
      baseCategories?.reduce((filteredCategories: NonNullableRewardCategory[], category) => {
        if (category?.rewards?.length && sanityRewardsMap) {
          const filteredRewards = category.rewards.filter(reward => {
            return (
              reward?._id &&
              sanityRewardsMap[reward._id] &&
              engineRewardsMap[reward?.loyaltyEngineId || '']
            );
          });
          if (filteredRewards.length) {
            filteredCategories.push({ ...category, rewards: filteredRewards || [] });
          }
        }
        return filteredCategories;
      }, []),
    [baseCategories, engineRewardsMap, sanityRewardsMap]
  );

  return rewardCategories ?? [];
};

interface ILoyaltyRewardsProps {
  isWidget?: boolean;
}

/**
 *
 * LoyaltyRewards displays a list of available rewards that can be redeemed at checkout
 *
 */

const LoyaltyRewards: FC<React.PropsWithChildren<ILoyaltyRewardsProps>> = ({
  isWidget = false,
}) => {
  const { user } = useAuthContext();
  const [selectedRewardIdFromWidget, setSelectedRewardIdFromWidget] = useState('');
  const { engineRewardsMap, loading, sanityRewardsMap, refetchRewards } = useLoyaltyRewardsList();

  const { rewardsEvaluationMap } = useRewardsEvaluation();

  const { logRBIEvent } = useCRMEventsContext();

  const { formatMessage } = useIntl();

  const params = useLocalSearchParams<{
    rewardId: string;
    category: string;
  }>();
  const { rewardId, category: preselectedCategoryLabel } = params || {};
  const { rewardRedemptionMode } = useRewardsFlow();

  // If using as widget, the reward ID is saved in state
  const selectedRewardId = isWidget ? selectedRewardIdFromWidget : rewardId;

  // Local state for opened accordions
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const rewardCategories = useFilteredRewardCategories();

  const handleRewardOnPress = useCallback(
    (incentiveId: string, incentiveName: string, incentiveSanityId: string) => {
      if (selectedRewardId !== incentiveSanityId) {
        logRBIEvent({
          name: CustomEventNames.REWARD_SELECTED,
          type: EventTypes.Other,
          attributes: {
            engineId: incentiveId,
            sanityId: incentiveSanityId,
            name: incentiveName,
          },
        });
        if (isWidget) {
          setSelectedRewardIdFromWidget(incentiveSanityId);
          return;
        }
      }

      router.navigate({
        pathname: `${routes.loyaltyRewardsList}/${incentiveSanityId}`,
        params: { rewardRedemptionMode },
      });
    },
    [rewardRedemptionMode, isWidget, logRBIEvent, selectedRewardId]
  );

  // TODO: BK - remove this code for brands with SHOULD_EXPAND_CATEGORIES === false
  // Set default opened accordion based on pre-selections
  const rewardCategoriesMap = keyBy(rewardCategories, cat => cat?.label?.en);
  const defaultCategory = getRewardCategoriesDefaultSelection(
    rewardCategories,
    preselectedCategoryLabel,
    selectedRewardId
  );
  useEffect(() => {
    if (SHOULD_EXPAND_CATEGORIES) {
      const isSelectedCategoryAvailable = selectedCategories.some(cat => rewardCategoriesMap[cat]);
      if (!selectedCategories.length || !isSelectedCategoryAvailable) {
        const categoryLabel = defaultCategory?.label?.en;
        if (categoryLabel) {
          setSelectedCategories([categoryLabel]);
        }
      }
    }
    // selectedCategories should not be in the deps, to prevent setting defaultCategory when all accordions are manually closed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCategory]);

  useRumPageView(
    `loyalty-rewards${selectedRewardId ? '-detail' : '-list'}`,
    `Loyalty Rewards${selectedRewardId ? ' Detail' : ' List'}`
  );

  if (loading) {
    return (
      <LoyaltyLoaderContainer>
        <LoadingAnimation />
      </LoyaltyLoaderContainer>
    );
  }

  if (!user?.loyaltyId) {
    return <LoyaltyNotEnrolled />;
  }

  const selectedReward = sanityRewardsMap?.[selectedRewardId || ''];

  if (sanityRewardsMap && selectedRewardId && !selectedReward) {
    return (
      <NotFound>
        <LoyaltyIncentiveNotAvailableModal />
      </NotFound>
    );
  }

  return rewardCategories?.length && sanityRewardsMap ? (
    <LoyaltyRewardsView
      selectedReward={selectedReward}
      handleOnPress={handleRewardOnPress}
      sanityRewardsMap={sanityRewardsMap}
      rewardCategories={rewardCategories}
      engineRewardsMap={engineRewardsMap}
      formatMessage={formatMessage}
      selectedCategories={selectedCategories}
      setSelectedCategories={setSelectedCategories}
      refetchRewards={refetchRewards}
      isRefreshingRewards={loading}
      rewardsEvaluationMap={rewardsEvaluationMap}
      isWidget={isWidget}
      setSelectedRewardIdFromWidget={setSelectedRewardIdFromWidget}
    />
  ) : null;
};

export default LoyaltyRewards;
